<div class="header-container">
    <div class="header-main-contain">
      <div class="imgs-contain">
        <img
          class="logo-confia"
          src="../../../../assets/LG-Confia-Color-01.svg"
          alt="logo confia"
        />
      </div>
      <nav class="header-nav">
        <ul class="nav-list">
          <a class="nav-item" routerLinkActive="activeRoute" [routerLink]="['/prestatario']" [queryParams]="{ id: this.idBorrower }">
            DETALLES
          </a>
          <a
          *ngIf="cuotasRetrasadas == 'false' && !isAMA"
            class="nav-item"
            [routerLink]="['/prestatario/simularAmortizacion', idBorrower]"
            routerLinkActive="activeRoute"
          >
            AMORTIZAR
          </a>
          <a
          *ngIf="cuotasRetrasadas == 'true'"
            class="nav-item"
            [routerLink]="['/prestatario/pagarCuotasRetrasadas', idBorrower]"
            routerLinkActive="activeRoute"
          >
            PAGAR CUOTAS
          </a>
          <a
            class="nav-item"
            [routerLink]="'/prestatario/paymentMethods'"
            routerLinkActive="activeRoute"
          >
            MEDIOS DE PAGO
          </a>
        </ul>
      </nav>
      <mat-divider color="primary"></mat-divider>
    </div>
  </div>
