import { UsersModel } from './../../models/users/users';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Movement, MovementDetails } from '../../models/collections';
import { InvestmentList } from '../../models/collections/investments';
import { DocumentsModel } from '../../models/Affiliate';
import { IbanModel } from '../../models/borrower/payment-method';

@Injectable({
  providedIn: 'root'
})
export class UserService /* implements UserAbstractService  */{

  constructor( private http: HttpClient) { }

  public getUsers(): Observable<UsersModel[]> {
    return this.http.get<UsersModel[]>(`${environment.baseUrl}/api/users`)
  }
  public getUser(id: string): Observable<UsersModel> {
    return this.http.get<UsersModel>(`${environment.baseUrl}/api/users/${id}`)
  }
  public getUserByEmail(userEmail: string): Observable<UsersModel> {
    return this.http.post<UsersModel>(`${environment.baseUrl}/api/usersList`, userEmail)
  }
  public getCollections(id: string):Observable<any[]>{
    return this.http.get<any[]>(`${environment.baseUrl}/api/users/${id}/inversionesList`)
  }
   public getMovements(id: string, item:any, bIni:string, bEnd:string):Observable<Movement[]>{

    return this.http.get<Movement[]>(`${environment.baseUrl}/api/users/${id}/monederoList?${item}${bIni}${bEnd}`)
  }
  public getCreditList(id:string): Observable<any>{
    return this.http.get<UsersModel>(`${environment.baseUrl}/api/users/${id}/creditos`)
  }

  public getmovementDetails(id:string):Observable<MovementDetails[]>{
    return this.http.get<MovementDetails[]>(`${environment.baseUrl}/api/users/${id}/monederoDetail`)
  } 

  public getInvestmentList(id: string):Observable<InvestmentList[]>{
    return this.http.get<InvestmentList[]>(`${environment.baseUrl}/api/users/${id}/inversionesList`)
  } 
  public getUserDocuments(id: string): Observable<DocumentsModel>{
    return this.http.get<DocumentsModel>(`${environment.baseUrl}/api/users/${id}/documentsList`)
  }
  public getUserAccount(account:string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}${account}`)
  }
  public generateIbanId(data: IbanModel): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}/api/accounts`, data)
  }
  public setUserAccount(id: string, account: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/users/${id}`,{account: account})
  }
  public setAffiliateAccount(id: string, account: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/afiliados/${id}`,{account: account})
  }
  public resetPasswordEmail(email:string): Observable <any>{
    return this.http.post<any>(`${environment.baseUrl}/api/sendResetPasswordEmail`,{'email': email})
  }
  public resetPassword(token : string , password: string): Observable <any>{
    return this.http.post<any>(`${environment.baseUrl}/api/resetPassword/${token}`, {'password':password})
  }
  
}
