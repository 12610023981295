<div class="reg_container">
    <div class="page_container">
        <img src="../../../../assets/img/logo azul.png" alt="logo_confia" routerLink="/" style="cursor:pointer">
        <div class="reg_content">
            <div class="title_maintenance">
                <h2>Estamos de mantenimiento</h2>
                <img src="../../../assets/img/loadingPoints.gif" alt="loading_gif">
            </div>
            <img src="../../../assets/img/logo azul.png" alt="logo_confia">
            <h3>En estos momentos estamos actualizando nuestros servidores.</h3>
            <p>Esperamos estar operativos de nuevo a lo largo del día.</p>
            <p>Nos puedes escribir a <span>soporte.confia&#64;confia.com.es</span> si tienes alguna duda. Gracias por tu paciencia.</p>
        </div>
    </div>
</div>
