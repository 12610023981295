<div class="reg_container">
  <div class="Page_container">
      <app-nav-legal-info></app-nav-legal-info>
      <div class="reg_content">
          <div class="title_content">
              <h2>Condiciones de nuestra entidad de pago.</h2>
          </div>
          <div class="columns">
              <div class="forms_container">
                <div class="paymentConditions">
                  <h2>Términos y condiciones de nuestra entidad de pagos</h2>
                  <h3>CONTRATO DE LOS SERVICIOS DE PAGO</h3>
                  <p>
                    Condiciones Generales de Uso - Versión 1.1 05/06/2015 "Marketplace" Acuerdo
                    entre El Cliente y Lemon Way, Sociedad Limitada con un capital de 860.232,53
                    €, Número SIREN 500 486 915, con sede en el número 14, rue de la Beaune, 93100
                    Montreuil, Francia (referida de ahora en adelante como «Lemon Way»),
                    acreditada el 24/12/2012 por la “Autorité de Contrôle Prudentiel et de
                    Régulation” («ACPR», Francia, página web http://acpr.banque-france.fr) 61 rue
                    Taitbout 75009 París, como Institución de Pago híbrida, bajo número 16 568 J.
                  </p>
                  <h3>PRÓLOGO</h3>
                  <p>
                    Estas «Condiciones Generales de Uso de los Servicios de Pago» o «Condiciones
                    de Servicio» estarán disponibles en cualquier momento en la página web
                    (https://www.LemonWay.fr) y regulan los términos y condiciones para la
                    apertura de una Cuenta de Pago en LEMON WAY en nombre del Cliente y la
                    prestación de los servicios de pago. Recomendamos al Cliente leerlos
                    detenidamente antes de aceptarlos. El Cliente puede, en todo momento,
                    consultar, reproducir, almacenar en su ordenador u otro medio, reenviar por
                    correo electrónico o imprimir estas condiciones en papel con el fin de
                    conservarlas. También podrá obtener una copia de las mismas por correo de
                    forma gratuita a su dirección, realizando una solicitud expresa a LEMON WAY.
                    En cualquier momento, y de acuerdo con la ley, es posible verificar la
                    acreditación de LEMON WAY como entidad de pago en el sitio regafi.fr. La
                    página web de la entidad de pago LEMON WAY es la siguiente: www.lemonway.fr
                  </p>
                  <h3>1- APLICACIÓN</h3>
                  <p>
                    El Contrato Marco de los Servicios de Pago está formado por estas Condiciones
                    de servicio, el formulario de apertura de la cuenta de pago y las condiciones
                    de fijación de precios (el «Contrato»). Estos documentos forman un todo y
                    regulan los términos y condiciones de uso de los servicios de pago a los
                    Clientes proporcionados por la empresa LEMON WAY.
                  </p>
                  <h3>2- DEFINICIONES</h3>
                  <p>
                    Los términos utilizados en estas Condiciones de Servicio serán, siempre y
                    cuando se utilicen con las primeras letras en mayúsculas e independientemente
                    de si se usan en singular o plural, como se definen a continuación: -
                    Beneficiario: persona física o jurídica designada por el Cliente para recibir
                    una Transacción de Pago realizada por LEMON WAY de acuerdo con el Contrato. El
                    Beneficiario puede ser otro Cliente, un tercero, o el Cliente que paga. -
                    Cliente: persona física o jurídica y Titular de la Cuenta de Pago. - Cuenta de
                    Pago: cuenta abierta en los libros de LEMON WAY con el objetivo de
                    proporcionar Transacciones de Pago de débito y de crédito, sumas debidas por
                    el Cliente y cualquier inversión en relación con sus Operaciones, y para
                    compensar estas cantidades en la fecha de su cargo o abono de la Cuenta con el
                    propósito de mostrar un saldo neto de las Prestaciones disponibles de la
                    Cuenta. Este saldo no debe ser negativo que sea técnicamente o por
                    reglamentación. - Transacciones de Pago: aquellas acciones que consisten en el
                    pago, la transferencia o la retirada de fondos por transferencia bancaria de
                    su Cuenta de Pago, independientemente de cualquier obligación subyacente entre
                    el Cliente y el Beneficiario, ordenada por el Cliente. - Orden de Pago:
                    consentimiento dado por el Cliente siguiendo las medidas y procedimientos
                    personalizados acordados entre el Cliente y LEMON WAY, a fin de autorizar una
                    Transacción de Pago. - Prestación: cantidad acreditada de la Cuenta de Pago
                    disponible que puede solicitarse por futuras Transacciones de Pago tras la
                    recepción de una Orden de Pago del Cliente, el Propietario de la Cuenta. -
                    Servicios de Pago: servicios ofrecidos por LEMON WAY en aplicación del
                    Contrato y que incluyen la ejecución de transferencias bancarias y la
                    adquisición de órdenes de pago con tarjeta y mediante transferencia bancaria.
                    - Página Web: Se refiere a la página web http://www.lemonway.eu desde la que
                    LEMON WAY ofrece los Servicios de Pago. - Página Web de Partner: Se refiere a
                    la página web del Partner cuyos detalles se indican en el formulario de
                    apertura de Cuenta, a estos efectos, en calidad de Beneficiario, empresa
                    proveedora, o intermediario entre el Cliente y el Beneficiario de la
                    Transacción de Pago.
                  </p>
                  <h3>3- ABRIR UNA CUENTA DE PAGO</h3>
                  <p>
                    El Cliente debe cumplir los requisitos del procedimiento de apertura de la
                    Cuenta de Pago que se describen a continuación. 3.1 – Declaraciones Previas
                    del Cliente El Cliente, persona mayor de edad y jurídicamente capaz, o persona
                    jurídica, declara expresamente que tiene la capacidad y/o ha recibido las
                    autorizaciones necesarias para utilizar el servicio de pago proporcionado por
                    LEMON WAY y se compromete a indemnizar a LEMON WAY contra toda posible
                    responsabilidad derivada de una declaración falsa. El Cliente declara que está
                    actuando en su propio nombre. El Cliente tiene la obligación de utilizar los
                    servicios prestados por LEMON WAY de buena fe, con fines lícitos y respetando
                    las disposiciones del Contrato. El Cliente, como persona física, declara que
                    reside en el Espacio Económico Europeo o AELC; el Cliente, como persona
                    jurídica, declara que está registrado en el Espacio Económico Europeo, o AELC.
                    Para el resto de países de residencia o registro, LEMON WAY retiene la
                    autoridad de no revisar la solicitud de apertura de una Cuenta de Pago con el
                    fin de ajustarse a los límites geográficos en su acuerdo. La lista de países
                    en los que la Entidad de Pago de LEMON WAY admite clientes está disponible en
                    cualquier momento en la página web https://www.regafi.fr 3.2 - Condiciones de
                    la firma del Contrato El formulario de apertura de la Cuenta de Pago será
                    firmado por el Cliente tras ser informado de las disposiciones del Contrato.
                    Para ello, podrán proporcionar una firma manuscrita en una versión impresa
                    enviada por correo a la dirección de la sede central de LEMON WAY en la
                    primera página de estas Condiciones de Servicio. El uso del módulo de firma
                    electrónica se proporciona al Cliente en la Página Web de un Partner. El
                    Cliente acepta que ha leído, entendido y aceptado el Contrato Marco en su
                    totalidad. 3.3 - Documentación que debe aportarse para la identificación El
                    Cliente acepta que la Página Web de un Partner proporciona a LEMON WAY los
                    siguientes elementos: Para personas físicas: - Una copia de una prueba de
                    identidad actual y válida que sea legible y aceptable por LEMON WAY a su
                    discreción, como una tarjeta de identidad o un pasaporte, - Para ciertos
                    límites, LEMON WAY solicitará la copia de una segunda prueba de identidad, -
                    Una copia de una factura (agua, gas, electricidad, teléfono fijo o ADSL o
                    televisión por cable, o un comprobante de pago de impuestos recibidos durante
                    los 3 últimos meses, o un recibo del alquiler con el nombre completo y la
                    dirección del propietario) - Una copia de un IBAN antes de que Lemon Way pueda
                    realizar una transferencia bancaria externa específica al banco del Cliente
                    que posea la Cuenta de Pago. Para personas jurídicas: - Una copia de los
                    estatutos debiodamente registrados en el Registro Mercantil (incluyendo la
                    distribución de accionistas), excepto para las empresas que coticen en bolsa.
                    - Un copia del registro de Empresa de los últimos 3 meses - Una copia de una
                    prueba de identidad del representante legal de la empresa y una segunda prueba
                    de identidad a petición de LEMON WAY - Una copia de un IBAN en nombre del
                    Cliente; - Se pedirán los mismos documentos al beneficiario de cualquier
                    Cliente como persona física. - Para asociaciones, una copia también del número
                    de registro oficial, además de una prueba de identidad y dirección del
                    Presidente de la asociación o el Tesorero, además de un IBAN en nombre de la
                    asociación. LEMON WAY se reserva el derecho de solicitar cualquier otro
                    documento o información adicional, con el fin de poder llevar a cabo
                    verificaciones en relación con sus obligaciones legales, incluyendo aquellas
                    en relación con la lucha contra el blanqueo de capitales. El Cliente puede
                    autorizar por escrito, u otro medio duradero, a un tercero a transmitir estos
                    documentos en su nombre a LEMON WAY. Indicarán en el formulario de apertura de
                    la Cuenta el nombre registrado de la entidad comercial. Se informa al Cliente
                    de que LEMON WAY conservará en archivos electrónicos, durante cinco (5) años
                    tras haber terminado la relación con los clientes, una copia del documento o
                    documentos que usaron como identificación. 3.4 - Aceptación de la apertura de
                    una Cuenta de Pago LEMON WAY puede negarse a abrir una cuenta de pago por
                    cualquier motivo y sin justificar su decisión. Esto no podrá ser motivo de
                    daños y perjuicios. La Página Web del Partner puede emitir la confirmación o
                    el rechazo de la Cuenta de Pago de LEMON WAY enviando un correo electrónico al
                    Cliente. El Cliente puede utilizar esta aceptación para identificarse en la
                    Página Web del Partner para determinar que su Cuenta de Pago está abierta.
                  </p>
                  <h3>4- ACREDITAR UNA CUENTA DE PAGO</h3>
                  <p>
                    4.1 - Con tarjeta y por transferencia bancaria El Cliente puede proceder a la
                    financiación de su Cuenta de Pago por transferencia de su cuenta bancaria o
                    con la tarjeta bancaria La Orden de Pago se considera irrevocable desde la
                    introducción de los datos de la tarjeta o, en el caso del registro de la
                    tarjeta, de la introducción del criptograma. LEMON WAY puede rechazar el
                    registro de una cuenta bancaria o tarjeta de pago, o cancelar este registro en
                    cualquier momento por razones de seguridad. El Cliente deberá, en este caso,
                    introducir los números de su cuenta bancaria o tarjeta de pago cada vez que
                    necesite depositar fondos en su cuenta. LEMON WAY regula límites que pueden
                    ser más restrictivos que los límites del banco o emisor de la tarjeta de pago,
                    en interés de la protección del Cliente titular. Se aplican a la plataforma
                    límites únicos, límites por dia, por mese y por año, además de cualquier otro
                    tipo de restricción para luchar contra el fraude. Se informa al Cliente de que
                    cualquier operación que pueda resultar en la superación de los límites
                    aplicables, será automáticamente rechazada por el Sistema LEMON WAY. Todas las
                    transacciones por tarjeta de pago que no se paguen, hayan sido rechazadas o
                    hayan sido suspendidas, verán esa cantidad automáticamente deducida de su
                    Provisión de Cuenta de Pago por Lemon Way. Si la Prestación es insuficiente,
                    LEMON WAY está autorizado a utilizar todas las vías de apelación en contra del
                    Cliente con el fin de recuperar la cantidad adeudada. Además, LEMON WAY tendrá
                    el derecho de rechazar la ejecución de todas las remesas futuras realizadas
                    por la tarjeta que causó el incidente. Las tasas para el procesado de pagos no
                    pagados, rechazados, o suspendidos, podrán ser recaudadas por LEMON WAY, hasta
                    un total del 15% de su importe, sin ser nunca superiores a 20 euros, de
                    acuerdo con el artículo L. 133-19 del Código Monetario y Financiero. 4.2 - Por
                    transferencia bancaria desde otra Cuenta de Pago Una Cuenta de Pago también se
                    puede financiar mediante transferencia bancaria de una Cuenta de Pago de un
                    Cliente a la Cuenta de Pago del beneficiario La Orden de Pago se recibe de
                    acuerdo con el punto 5.1 de la cuenta del Beneficiario. 4.3 - Registro de
                    fondos de la Cuenta LEMON WAY registrará los fondos resultantes de la
                    obtención de una Orden de Pago con tarjeta o por transferencia bancaria lo
                    antes posible y, a más tardar, al final de la jornada de trabajo durante la
                    cual fueron recibidos por LEMON WAY de acuerdo con el punto 4.1 y en tiempo
                    real para transferencias electrónicas realizadas de acuerdo con el punto 4.2.
                  </p>
                  <h3>5- CARGO DE UNA CUENTA DE PAGO POR TRANSFERENCIA BANCARIA</h3>
                  <p>
                    5.1 - Inicio de una Orden de Pago LEMON WAY ofrece un servicio de pago que
                    permite a los Clientes con una Cuenta de Pago instruir a LEMON WAY, con el fin
                    de llevar a cabo una transferencia bancaria sujeta a aprobación de que la
                    Prestación de la Cuenta sea mayor que la cantidad total de la transferencia
                    bancaria (incluidos los honorarios). En caso de que la Prestación sea
                    insuficiente, se rechazará automáticamente la Orden de Pago. La Orden de Pago
                    debe incluir la siguiente información: - el importe en euros; - el
                    Beneficiario identificado por su número de Cuenta de Pago; - la fecha de la
                    transferencia bancaria se diferencia de la orden del Cliente cuando la
                    transferencia de fondos se ha introducido, ejecutado con la condición de que
                    se cumpla la medida. Por tanto, la fecha es el fin del período de suscripción
                    sujeto a que se cumpla la medida. Si la Prestación de la Cuenta de Pago es
                    insuficiente, el Cliente puede llevar a cabo una financiación complementaria
                    con el fin de lograr una Prestación suficiente para proceder con el pago. La
                    autorización de Orden de Pago está sujeta a la recepción de los fondos
                    complementarios que brindan la Prestación una cantidad suficiente para
                    ejecutar la Transacción de Pago. LEMON WAY recuerda al Cliente que cuando la
                    divisa de la Cuenta de Pago difiere de la de la cuenta bancaria a la que
                    llegará la transferencia bancaria, las tasas de cambio o de servicio, podrán
                    ser imputadas por el banco una vez que la cuenta bancaria del Cliente esté
                    abierta. LEMON WAY no se hace responsable si los datos bancarios que se
                    indiquen al solicitar una transferencia bancaria son incorrectos o no están
                    actualizados. 5.2 - Irrevocabilidad de una Orden de Pago La Orden de Pago
                    ejecutada de forma suficiente por un Cliente será irrevocable ya que la
                    entrada de un código de uso único se ajusta al punto 5.1, y el Cliente no
                    podrá, por lo tanto, solicitar que sea cancelada. Se detalla que, en ciertos
                    casos, el Cliente será capaz de iniciar una orden en grupo que incluya una
                    transferencia de fondos por las tarjetas iniciadas de acuerdo al punto 4.1
                    anterior y una Orden de Pago por transferencia bancaria a la Cuenta de Pago de
                    un Beneficiario designado en una fecha determinada. La Orden de Pago se
                    considerará irrevocable una vez que se hayan introducido los datos de la
                    tarjeta tal y como se describe en 4.1. LEMON WAY no realiza transferencias
                    bancarias recurrentes. 5.3 - Cantidades límites y límites aplicables El
                    Cliente está sujeto a los siguientes límites estándar: - Si el Cliente es un
                    individuo, se le informa que está sujeto a un límite de 2.500 euros por año
                    natural y 1.000 euros por pago. Para utilizar la Cuenta de Pago para
                    cantidades mayores, LEMON WAY solicitará la de identificación correcta del
                    Cliente. - Si el Cliente es una persona jurídica. LEMON WAY exigirá
                    sistemáticamente todos los documentos de identidad necesarios antes de la
                    apertura de la Cuenta de Pago. Cualquier transacción de pago que exceda los
                    límites aplicables a la cantidad mensual acumulativa será rechazada
                    automáticamente por el Sistema LEMON WAY. Otros límites o bloqueos de órdenes
                    podrán ser activados en cualquier momento por LEMON WAY en caso de que haya
                    riesgo de fraude. LEMON WAY se reserva el derecho a anular una Transacción de
                    Pago si la operación de transferencia de fondos mediante transferencia
                    bancaria o tarjeta de pago utilizada para acreditar la Cuenta de Pago es
                    rechazada o suspendida por el emisor de la tarjeta. 5.4 - Retrasos Los
                    retrasos máximos para los servicios de pago, de acuerdo con la Orden del 29 de
                    julio de 2009, la aplicación del artículo L.314-2 del Código Monetario y
                    Financiero, son los siguientes: - las operaciones de pago iniciadas durante un
                    día laborable se realizarán a más tardar por LEMON WAY el siguiente día
                    laborable si se realizan en euros utilizando una entidad de crédito local en
                    un estado miembro de la Unión Europea; - las operaciones de pago iniciadas
                    durante un día laborable se realizarán a más tardar por LEMON WAY al final del
                    día laborable si se realizan en euros a favor de otra Cuenta de Pago.
                  </p>
                  <h3>6- INFORMES</h3>
                  <p>
                    6.1 - Con transacción Una vez se realiza una Transacción de Pago, el Sistema
                    LEMON WAY o la Página Web del Partner envía automáticamente un correo
                    electrónico de confirmación de la Transacción al Cliente antes de iniciar la
                    Orden de Pago. Este correo electrónico contiene toda la información relativa a
                    la Transacción de Pago que ha sido comunicada por el Sistema LEMON WAY,
                    incluyendo: la identidad del Beneficiario, los detalles de la Transacción de
                    Pago, su importe, la fecha y la hora de la Transacción, además de las
                    condiciones específicas del pago. La información contenida en el correo
                    electrónico también estará disponible en su Área de Cliente. 6.2 Resúmenes
                    Todas las Transacciones de Pago están a su disposición en un Resumen realizado
                    en tiempo real para cada Cuenta de Pago. El Cliente podrá consultar su Resumen
                    en la Página Web del Partner. El Cliente tendrá acceso a los resúmenes de las
                    Cuentas de Pago de todas las Transacciones de Pago, para créditos y débitos en
                    esta cuenta. El resumen anual de las tarifas está disponible durante el mes de
                    enero cada año. Se les enviará una notificación para informarles de que este
                    documento se ha subido. El período de consulta se mantiene durante dos (2)
                    años, además del año en curso. LEMON WAY conservará los documentos
                    justificativos, durante los períodos reglamentarios aplicables, registros y
                    documentos de Transacciones de pago finalizadas, en archivos digitales.
                  </p>
                  <h3>7- DURACIÓN DEL CONTRATO Y FECHA DE EFECTO</h3>
                  <p>
                    El Contrato entra en vigor en el momento en que se aceptan los términos de
                    este documento, por un período indefinido. El Cliente dispone de un plazo de
                    catorce (14) días para cancelar su contrato sin pagar honorarios. Este breve
                    período será desde el día en que se firme el contrato, es decir, el día en que
                    el Cliente acepta las presentes Condiciones Generales del Servicio. Durante
                    este período de cancelación, el contrato sólo podrá empezar a petición expresa
                    del Cliente. El Cliente reconoce y acepta expresamente todas las instrucciones
                    de pago dirigidas a LEMON WAY antes de finalizar el período de cancelación,
                    proporcionando una solicitud expresa del Cliente de cumplir el contrato. El
                    Cliente, por tanto, no retendrá el derecho de cancelar una instrucción de pago
                    que pudiera haber dado y confirmado durante el período de cancelación. Este
                    derecho de cancelación puede ser ejercido por el Cliente sin penalizaciones y
                    por cualquier razón. Se requiere que el Cliente notifique a LEMON WAY de su
                    decisión por correo certificado con acuse de recibo a la sede central de LEMON
                    WAY que se encuentra en la definición de LEMON WAY (en la introducción de las
                    presentes Condiciones Generales de Uso) antes de la finalización de este
                    periodo de catorce días . Si el Cliente no ejerce su derecho a cancelar, el
                    contrato permanecerá en efecto, de acuerdo con lo dispuesto en las presentes
                    Condiciones Generales del Servicio. Para cancelar el Contrato, se deberán
                    cumplir las condiciones de cancelación del artículo 19.
                  </p>
                  <h3>8- RECLAMACIONES</h3>
                  <p>
                    Las reclamaciones relacionadas con las relaciones entre dos Clientes o entre
                    un Cliente y un tercero no serán válidas para LEMON WAY. Sólo se mencionarán
                    en el este artículo y el Contrato aquellas en relación con la ausencia o la
                    ejecución incorrecta de una Orden de Pago determinada por el Cliente a LEMON
                    WAY. Las reclamaciones (disputas, derechos de oposición, de acceso y de
                    rectificación, etc.) pueden ser ejercidas libremente mediante solicitudes
                    dirigidas a LEMON WAY en la siguiente dirección de correo electrónico:
                    reclamation&#64;lemonway.fr o por correo a la siguiente dirección: LEMON WAY
                    Servicio de Quejas 14, rue de la Beaune 93100, Montreuil, Francia Cualquier
                    queja o solicitud con relación a: - el fallo de una de las funciones del
                    Servicio de Pago proporcionado por LEMON WAY o la Página Web, - información
                    comunicada por el sistema Lemon Way o LEMON WAY como parte del Servicio de
                    Pago. - un error en la ejecución de un pago o la inexistencia de la ejecución,
                    - un error en el débito de la comisión, impuestos o cuotas bancarias por LEMON
                    WAY, el Cliente debe informar a LEMON WAY lo antes posible desde el día en que
                    el Cliente tuviera constancia o creyera tener constancia del mismo o en el
                    resto de períodos más largos indicados por disposiciones particulares o por la
                    ley. De acuerdo con la recomendación 2011-R-05 de la ACPR el 15 de diciembre
                    de 2011, el reconocimiento se enviará en un plazo máximo de diez días. Las
                    reclamaciones se tramitarán en un plazo máximo de dos meses a partir de su
                    recepción. Tiene disponible también un formulario de declaración de quejas en
                    nuestra Página Web: www.lemonway.fr/reclamation Si no se logra un
                    acuerdo amistoso, el Cliente no empresarial podrá ponerse en contacto, por
                    correo, con un defensor del pueblo independiente que se pueda contactar
                    libremente en caso de litigio, que surja de o esté relacionado de alguna
                    manera con estos términos, el Defensor del Pueblo de AFEPAME, 36 rue de
                    Taitbout 75009 París, Francia, y sin perjuicio de otras vías de acción legal.
                  </p>
                  <h3>9- HONORARIOS</h3>
                  <p>
                    En consideración a la prestación de servicios de pago al Cliente, LEMON WAY
                    impondrá una remuneración cuyo importe y condiciones se indican en la Página
                    Web de Partner en la pestaña «Comisiones de Serivico». Las tasas indicadas son
                    las comisiones financieras y abarcan los honorarios asociados y los de LEMON
                    WAY.
                  </p>
                  <h3>10- SEGURIDAD</h3>
                  <p>
                    10.1 - Obligación de Notificación El Cliente tiene la obligación de informar
                    inmediatamente a LEMON WAY si sospecha cualquier uso o acceso fraudulento a su
                    Cuenta de Pago o cualquier evento que pueda conducir a tal uso, incluidos pero
                    no quedar limitados a: pérdida, divulgación accidental o apropiación indebida
                    de sus Nombres de usuario para la Cuenta de Pago, el acceso no autorizado a la
                    totalidad o parte del Área de Cliente o un pago no autorizado. Esta
                    notificación deberá enviarse por correo electrónico a la dirección de correo
                    electrónico siguiente: fraude&#64;lemonway.fr y confirmarse por escrito a la
                    siguiente dirección: Société LEMON WAY 14 rue de la Beaune 93100, Montreuil,
                    Francia Francia 10.2 - Prevención LEMON WAY hace todo lo posible para detener
                    el resto de usos fraudulentos de la Cuenta de Pago. Los Partners también
                    tienen sus propios métodos seguros de comunicación con el Cliente. 10.3- Uso
                    de cookies LEMON WAY puede usar, como parte del Servicio de Pago, cookies
                    (archivos enviados por el servidor de LEMON WAY que se registran en su disco
                    duro mientras navega). Estas cookies se utilizan en primer lugar para mejorar
                    el Servicio de Pago, sobre todo en velocidad. Se le indica al Cliente que
                    puede rechazar cookies del Sistema LEMON WAY en la configuración del
                    navegador, pero esto podría alterar su experiencia con el Servicio de Pago.
                    10.4 - Interrupción del servicio de LEMON WAY LEMON WAY se compromete a
                    establecer todos los recursos razonables a su alcance para garantizar un
                    servicio continuo. LEMON WAY sin embargo no garantiza un acceso continuado e
                    ininterrumpido al servicio. Por lo tanto, LEMON WAY no se hace responsable de
                    los retrasos y/o la imposibilidad de acceder al Sistema LEMON WAY que
                    provoquen la imposibilidad de realizar Transacciones de Pago o en el caso de
                    servicio erróneo o parcial, a causa de factores fuera del control razonable de
                    LEMON WAY. Se informa al Cliente que LEMON WAY ocasionalmente puede
                    interrumpir el acceso a la Página Web o a una parte o a todos sus servicios: -
                    para realizar reparaciones, mantenimiento, o añadir nuevas características, -
                    si hay sospechas de intento de piratería, malversación de fondos o cualquier
                    otro riesgo de infracción, - cuando personas o autoridades autorizadas se lo
                    soliciten o instruyan. - para ejecutar las operaciones de la Cuenta de Pago, -
                    para el cierre de una Cuenta de Pago. Una vez reanudado el servicio normal,
                    LEMON WAY intentará realizar esfuerzos razonables para tratar con las
                    operaciones pendientes en el menor tiempo posible. 10.5 - Oposición a una
                    medida de seguridad El Cliente puede realizar una oposición contactando con
                    Lemon Way por correo electrónico con support&#64;lemonway.Fr o por teléfono al
                    número: +33 1 48 18 19 30 Se crea un número de registro para esta oposición y
                    se guarda durante 18 meses. Antes del fin de este período, el Cliente puede
                    hacer una solicitud por escrito y LEMON WAY le proporcionará una copia de esta
                    oposición. LEMON WAY no se hace responsable de las consecuencias de una
                    oposición no originadas por un Cliente. La solicitud de la oposición se
                    reconoce por la fecha en que la solicitud fue recibida por LEMON WAY o
                    cualquier persona designada por ellos, para este propósito. En caso de robo o
                    uso fraudulento, LEMON WAY está autorizado a solicitar un recibo o una copia
                    del formulario de quejas del Cliente, que se compromete a responder en el
                    plazo más breve posible. LEMON WAY bloqueará el acceso a la Cuenta de Pago y
                    hará que el Nombre de usuario y la Cuenta de Pago del Cliente no se puedan
                    usar. Se le enviará al Cliente nueva información de usuario, de la misma
                    manera que se le envió por primera vez tras abrir su Cuenta de Pago.
                  </p>
                  <h3>11- RESPONSABILIDADES</h3>
                  <p>
                    De conformidad con el artículo L. 133-22 del Código Monetario y Financiero,
                    LEMON WAY es responsable, sin perjuicio de los artículos L. 133-5 y L. 133-21
                    del Código Monetario y Financiero, de ejecutar correctamente la Transacción de
                    Pago del Cliente que paga hasta que los fondos hayan sido recibidos por el
                    proveedor de servicios de pago del Beneficiario de la Transacción de Pago.
                    Cuando LEMON WAY sea responsable de una Transacción de Pago mal ejecutada
                    debido a su propia negligencia, LEMON WAY reembolsará, sin demora, el importe
                    al pagador y restaurará la cuenta de débito al estado en que habría estado si
                    no se hubiera ejecutado erróneamente la Transacción de Pago. Los clientes no
                    corporativos que deseen impugnar una Transacción de Pago que no hayan
                    autorizado deben ponerse en contacto con Atención al Cliente de acuerdo con el
                    artículo 8 lo más rápidamente posible tras darse cuenta de la anomalía y, a
                    más tardar, 13 meses después del registro de la Transacción de Pago en la
                    Cuenta. En caso del uso de una medida de seguridad, las transacciones no
                    autorizadas realizadas antes de la notificación de la oposición serán
                    responsabilidad del Cliente no corporativo, sujeto a un límite de 150 euros.
                    Sin embargo, LEMON WAY no se hace responsable en caso de error por parte del
                    Cliente, como por ejemplo negligencia intencionada o negligencia grave de sus
                    obligaciones, retraso en la presentación de una oposición o mala fe. En caso
                    de un mal uso o falsificación de sus datos, las pérdidas resultantes de las
                    Transacciones realizadas antes de la oposición del Cliente no corporativo
                    serán cubiertas por LEMON WAY, excepto en el caso de negligencia como quedó
                    definido anteriormente. Las Transacciones de Pago realizadas tras la
                    presentación de quejas no corporativas por clientes serán cubiertas por LEMON
                    WAY excepto en caso de fraude. LEMON WAY no tiene derecho a cancelar una Orden
                    de Pago irrevocable a petición del Cliente. LEMON WAY no es responsable de
                    forma alguna por daños directos y/o indirectos, como perjuicios comerciales,
                    pérdida de clientes, cualquier interrupción comercial, pérdida de ganancias,
                    daños a la marca sufridos por cualquier Cliente o por un tercero, y que
                    pudieran ser resultado de la actuación de LEMON WAY o la implementación de la
                    Plataforma de Operaciones, o su falta de disponibilidad. Cualquier recurso
                    interpuesto contra el Cliente por un tercero constituye una pérdida indirecta
                    y, por lo tanto, no da lugar a una indemnización. Salvo estipulación en contra
                    de estas Condiciones Generales de Uso o las leyes obligatorias, sin perjuicio
                    de los motivos de exclusión o limitación de responsabilidad establecida en
                    éstos, LEMON WAY no podrá, en ningún caso, hacerse responsable de los daños
                    causados ​​por un caso de fuerza mayor o un evento fuera de su control, o
                    aplicar medidas o disposiciones legales predominantes de las autoridades
                    francesas o extranjeras. Fuerza mayor son aquellos eventos extraordinarios
                    fuera de su control, en particular pero sin quedar limitados a: corte de
                    energía, incendio o inundaciones, huelgas de personal, subcontratistas o
                    proveedores, funcionamiento incorrecto de sistemas interbancarios o pagos con
                    tarjetas bancarias, guerra, disturbios, motines u ocupación de un territorio
                    por fuerzas extranjeras, negligencia por parte de terceros de cumplir con las
                    resoluciones y principios, como por ejemplo personas responsables por la
                    prestación de los servicios de energía eléctrica o telecomunicaciones.
                  </p>
                  <h3>12- PROTECCIÓN DE LOS FONDOS DEL CLIENTE</h3>
                  <p>
                    LEMON WAY retendrá los fondos disponibles depositados en la Cuenta de pago del
                    Cliente hasta el fin de cada día laborable en una cuenta de haberes abierta en
                    BNP Paribas.
                  </p>
                  <h3>13- CUENTA DE PAGO INACTIVA</h3>
                  <p>
                    Una Cuenta de Pago se considerará inactiva si: (i) la Cuenta de pago no ha
                    sido objeto de ninguna Transacción de Pago durante un periodo de doce meses
                    durante los cuales, a excepción de los cargos a LEMON WAY por el mantenimiento
                    de todo tipo de honorarios y comisiones de la cuenta y (ii) el Cliente dueño
                    de la Cuenta, su representante legal o persona autorizada por ellos no es,
                    claramente, de ninguna forma, parte de LEMON WAY, o (iii) Tras un período de
                    12 meses a partir de la muerte del Cliente. El Cliente y sus herederos son
                    informados por sus consiguientes consecuencias. Los activos registrados en la
                    Cuenta de Pago inactiva se depositarán en la Caisse des dépôts et
                    consignations tras un período de diez años a partir de la fecha de la última
                    Transacción de Pago sin incluir los cargos a LEMON WAY por el mantenimiento de
                    todo tipo de honorarios y comisiones. Excepto en el caso de la muerte del
                    titular de la cuenta de pago, en el que los activos registrados son
                    depositados en la Caisse des dépôts et consignations tras un período de tres
                    años a partir de la fecha de fallecimiento del titular.
                  </p>
                  <h3>14- PROPIEDAD INTELECTUAL</h3>
                  <p>
                    No se transferirá al Cliente ningún derecho de propiedad intelectual a través
                    de la utilización del Servicio de Pago o servicios prestados por LEMON WAY a
                    través del Sistema LEMON WAY, bajo estas Condiciones Generales del Servicio.
                    El Cliente se compromete a no afectar los derechos de LEMON WAY, de tal manera
                    que queda prohibida cualquier reproducción o adaptación de la totalidad o
                    parte de los activos y componentes intelectuales que conforman el Sistema
                    LEMON WAY y sus accesorios, independientemente del medio, ya sea actualmente o
                    en el futuro. Todos los derechos relacionados con el software del Sistema
                    LEMON WAY son propiedad, plena y total, de la empresa LEMON WAY. Son parte de
                    sus secretos comerciales e información confidencial, sin tener en cuenta el
                    hecho de que ciertos componentes puedan estar o no protegidos por la
                    legislación vigente de propiedad intelectual. El software del Sistema LEMON
                    WAY y, cuando sea apropiado también su documentación, serán reconocidos por el
                    Cliente como obra intelectual, y ellos y su personal estarán obligados a
                    considerarlo como tal y se les prohibirá copiar, reproducir, traducir a otro
                    idioma, adaptar, distribuir con o sin remuneración, o añadir al mismo
                    cualquier objeto que no cumpla con sus especificaciones. La marca «Lemon Way»
                    es propiedad de la empresa LEMON WAY. El Cliente se compromete a no retirar la
                    mención de la marca «Lemon Way» en cualquier elemento proporcionado o puesto a
                    su disposición por LEMON WAY, como software, documentos o carteles
                    publicitarios.
                  </p>
                  <h3>15- CONFIDENCIALIDAD</h3>
                  <p>
                    El Cliente se compromete a respetar la confidencialidad más estricta con
                    respecto a toda la información técnica, comercial o de otro tipo de la que el
                    Cliente tenga constancia como parte de la ejecución del Servicio de Pago. Esta
                    obligación de confidencialidad se mantendrá en vigor mientras dure la
                    suscripción al Servicio de Pago y durante tres años siguientes a la fecha de
                    cancelación del Contrato. Esta obligación de confidencialidad no se aplica a
                    la información que sea, o se haya vuelto, pública sin culpa del Cliente. Las
                    partes reconocen que las Transacciones de Pago están cubiertas por secreto
                    profesional de acuerdo con el artículo L.519-22 del Código Monetario y
                    Financiero.
                  </p>
                  <h3>16- RECOGIDA Y TRATAMIENTO DE DATOS PERSONALES</h3>
                  <p>
                    La empresa LEMON WAY respeta todas las disposiciones aplicables en lo que
                    respecta a la protección de la privacidad y en particular la modificación de
                    la Ley del 8 de diciembre de 1992, sobre la protección de la vida privada con
                    respecto al tratamiento de datos personales. De acuerdo con la ley, LEMON WAY
                    realiza el tratamiento de los datos personales de acuerdo con la declaración
                    CNIL. La empresa LEMON WAY recoge y conserva los datos personales que ha
                    proporcionado voluntariamente el Cliente. Por lo tanto, se trata de datos de
                    carácter personal relativos al Cliente como persona física, datos relativos a
                    su identidad, su número de teléfono, su dirección de correo electrónico, su
                    dirección, su número de tarjeta o cuenta bancaria; a la transferencia o
                    transacción bancaria o a la dirección IP de su ordenador. Si tiene algún
                    comentario o si cree que la política de privacidad de datos actual no se ha
                    respetado, puede ponerse en contacto con la empresa LEMON WAY, responsable del
                    tratamiento de estos datos, a la siguiente dirección. El Cliente queda
                    informado y acepta que LEMON WAY es responsable de la recogida y tratamiento
                    de los datos personales de la siguiente manera: - respetando todas las
                    disposiciones reglamentarias o estatutarias aplicables, especialmente en
                    relación con la prevención del blanqueo de dinero y la financiación del
                    terrorismo, - transacciones de procesado/gestión y archivado, - controlando y
                    previniendo incidentes e irregularidades (la lucha contra el fraude y
                    cualquier otro tipo de delito), - gestión centralizada de clientes, -
                    tramitación de solicitudes de los Clientes, - realizando pruebas, estadísticas
                    y encuestas, - formando personal asignado al Servicio de Pago, - controlando
                    la calidad del servicio, - sugiriendo nuevos servicios, Se informa al Cliente
                    de que dichos datos personales se pueden guardar en una o varias carpetas de
                    acuerdo con la legislación aplicable y el mismo acepta que los datos recogidos
                    se guardan y procesan de acuerdo con las reglas descritas anteriormente. El
                    Cliente acepta que los datos personales, estrictamente necesarios para el
                    cumplimiento de los objetivos antes mencionados o aquellos exigidos por la
                    normativa aplicable son transmitidos por LEMON WAY: - a subcontratistas y
                    proveedores de servicios externos cuya intervención es necesaria, - a
                    Beneficiarios de una Transacción de Pago o a un intermediario que se ponga en
                    contacto con el Cliente y el Beneficiario de la Transacción de Pago para la
                    correcta ejecución del mismo, - a los Partners comerciales de LEMON WAY. El
                    Cliente acepta que la comunicación de estos datos personales a estas personas
                    antes mencionadas pueda ser, de acuerdo con las condiciones previamente
                    definidas, a otro país de la Unión Europea y, por igual, a un país que no sea
                    miembro de la Unión Europea que garantice un adecuado nivel de protección en
                    lo que respecta la Ley del 8 de diciembre de 1992, sobre la protección de la
                    vida privada con respecto al tratamiento de datos personales. El Cliente tiene
                    derecho a acceder a los datos personales que les afecten y se le informa de
                    que puede consultar, en cualquier momento, la información que se ha comunicado
                    a LEMON WAY. El Cliente tiene el derecho de rectificar datos personales
                    inexactos, LEMON WAY le informa que puede modificar cierta información
                    personal de su Área de Cliente. El Cliente puede ejercer su derecho de
                    oposición sobre LEMON WAY a los tratamientos previstos relativos a los
                    servicios de pago u otros productos o servicios promovidos por LEMON WAY. Los
                    derechos de oposición, acceso y rectificación pueden ejercerse libremente
                    mediante solicitudes enviadas a LEMON WAY por correo electrónico a la
                    siguiente dirección: reclamation&#64;lemonway.fr o por correo a la siguiente
                    dirección: Société LEMON WAY Servicio de Quejas 14, rue de la Beaune 93100,
                    Montreuil, Francia
                  </p>
                  <h3>17- ACUERDO EN RELACIÓN CON LAS PRUEBAS</h3>
                  <p>
                    Las comunicaciones por correo electrónico son reconocidas como pruebas válidas
                    por el Cliente y LEMON WAY. Toda la información guardada en la base de datos
                    informática del Sistema Lemon Way, relacionada sobre todo con todos los
                    mensajes relacionados con el procesamiento de pagos y pagos recibidos de los
                    Clientes, a Solicitudes de Retirada y la ejecución de transacciones de LEMON
                    WAY; con las notificaciones emitidas por el Cliente y/o LEMON WAY; tienen,
                    hasta que se demuestre lo contrario, la misma fuerza probatoria que un medio
                    escrito y firmado, en cuestión de contenido y de fecha y hora en que fueron
                    enviados y/o recibidos. Estos rastros inalterables, seguros y fiables se
                    registran y se mantienen en los sistemas informáticos de LEMON WAY. Los
                    documentos de LEMON WAY que detallen esta información, además de las copias o
                    reproducciones de los documentos producidos por LEMON WAY, tienen la misma
                    fuerza probatoria que el original, hasta que se demuestre lo contrario.
                  </p>
                  <h3>18- BLOQUEO DE CUENTAS</h3>
                  <p>
                    La suspensión temporal e inmediata de una cuenta de pago puede ser pronunciada
                    por LEMON WAY por cualquier razón a juicio de LEMON WAY y en particular: - si
                    el Cliente no ha respetado las disposiciones del Contrato, - si el Cliente ha
                    proporcionado a LEMON WAY datos de identificación inexactos, obsoletos o
                    incompletos. - en caso de riesgo de fraude, lavado de dinero o financiamiento
                    del terrorismo, o cualquier riesgo que pudiera afectar la seguridad de la
                    Cuenta de Pago o el Sistema LEMON WAY; - en caso de que haya un aumento del
                    riesgo del Cliente de no poder cumplir con su obligación de pago; - en caso de
                    que LEMON WAY reciba un número significativo de los reembolsos, cancelación de
                    pedidos, o disputas a causa de órdenes no autorizadas. Se podrá tomar esta
                    decisión y notificar al Cliente por cualquier medio. La suspensión de una
                    Cuenta de Pago para asegurar la protección del Cliente, no puede ser, en
                    ningún caso, motivo del pago de daños punitivos para beneficio de éste. La
                    reactivación de la Cuenta de Pago será a discreción de LEMON WAY. Dependiendo
                    de la gravedad del incumplimiento de las Condiciones Generales de Uso, en
                    particular si el Beneficiario ha vendido Productos ilegales, LEMON WAY se
                    reserva el derecho de cancelar el Contrato de acuerdo con lo dispuesto en el
                    artículo 19.
                  </p>
                  <h3>19- CANCELACIÓN DEL CONTRATO</h3>
                  <p>
                    El Cliente puede cancelar el Contrato, lo cual provocará el cierre de su
                    Cuenta de Pago por correo certificado con acuse de recibo con un preaviso de
                    un mes. Deberán mantener la prestación suficiente para la terminación de las
                    Transacciones de Pago durante el período de aviso necesario para la
                    realización y el pago de todos sus cargos adeudados. LEMON WAY puede cancelar
                    el Contrato, lo cual provocará el cierre de su Cuenta de Pago por correo
                    certificado con acuse de recibo con un preaviso de dos meses. En el caso de
                    una violación grave por una de las Partes, el contrato se puede cancelar con
                    efecto inmediato mediante una simple notificación por escrito por cualquiera
                    de las Partes. Las violaciones graves por parte del Cliente se entienden como:
                    la comunicación de información falsa, actividad ilegal, contravenir la moral
                    adecuada, lavado de dinero o financiamiento del terrorismo, amenazas contra
                    personal de LEMON WAY o la Página Web del Partner, morosidad en el pago,
                    desprecio de una obligación del Cliente bajo estas reglas, terminación de
                    relaciones entre el Cliente y el Socio, endeudamiento o, para personas
                    jurídicas, el nombramiento de un representante legal, administrador, la
                    apertura de un procedimiento de queja o liquidación. Las violaciones graves
                    por parte de LEMON WAY podrán ser: la comunicación de información falsa,
                    desprecio de una obligación bajo estas reglas, el nombramiento de un
                    representante legal, administrador, la apertura de un procedimiento de queja o
                    liquidación. En caso de modificación de la normativa e interpretación
                    aplicables realizadas por la Autoridad Reguladora que afecten la capacidad de
                    LEMON WAY o de su representante legal para ejecutar operaciones de pago, el
                    Contrato se cancelará automáticamente. El Cliente será incapaz de transmitir
                    una Orden de Pago a partir de la fecha efectiva de la cancelación. La Cuenta
                    se puede mantener durante un período de 15 meses para cubrir disputas y
                    reclamaciones posteriores. Las Transacciones de Pago iniciadas antes de la
                    fecha de vigencia de la cancelación no serán desafiadas mediante solicitud de
                    cancelación y deben ejecutarse en los términos del Contrato. La cancelación
                    del Contrato provoca el cierre definitivo de la Cuenta de Pago. El cierre de
                    la Cuenta de Pago no puede dar motivo a indemnización, independientemente de
                    si los daños son creados por el cierre de esta Cuenta de Pago. El Cliente cuya
                    Cuenta haya sido cerrada por LEMON WAY no estará autorizado, salvo con la
                    autorización expresa de LEMON WAY, a abrir otra Cuenta de Pago. Cualquier
                    Cuenta de Pago abierta en violación de esta disposición podrá ser cerrada
                    inmediatamente, sin previo aviso, por LEMON WAY. La Disposición sobre la
                    Cuenta de Pago objeto de cierre proporcionará el derecho a una transferencia
                    bancaria a favor del Cliente dueño de esta cuenta siguiendo las instrucciones
                    de acuerdo con las transacciones en progreso y atrasos, rechazos bancarios o
                    disputas futuras. LEMON WAY se reserva el derecho a exigir una indemnización
                    en los tribunales como consecuencia de una violación del Contrato. El cierre
                    de la Cuenta de Pago podrá dar motivo a honorarios de acuerdo con el artículo
                    L. 314-13 del Código Monetario y Financiero.
                  </p>
                  <h3>20- MODIFICACIÓN DEL CONTRATO</h3>
                  <p>
                    Cualquier proyecto de modificación del Contrato se comunicará por escrito o
                    mediante cualquier otro soporte duradero al Cliente a más tardar dos meses
                    antes de la fecha en que los cambios propuestos entren en vigor. En ausencia
                    de una objeción por parte del Cliente, por escrito y correo certificado con
                    acuse de recibo, dirigida a LEMON WAY antes de la caducidad de este período de
                    dos meses, se considerará que el Cliente ha aceptado estos cambios. En caso de
                    rechazar los cambios propuestos, el Cliente puede cancelar el contrato de
                    forma gratuita, mediante solicitud por escrito antes de la fecha en que los
                    cambios propuestos entren en vigor. Esta solicitud no afectará a todos los
                    débitos (cargos, honorarios, pagos) de los que el Cliente seguiría siendo
                    responsable.
                  </p>
                  <h3>
                    21- DISPOSICIONES ESPECÍFICAS APLICABLES A CLIENTES COMO PERSONAS FISICAS
                  </h3>
                  <p>
                    21.1 - Muerte En el caso de la muerte del Cliente propietario de la Cuenta,
                    LEMON WAY deberá ser notificado lo antes posible por sus beneficiarios o
                    representante legal. Si esta notificación se da verbalmente, debe ser
                    confirmada por escrito. Tras la recepción de este escrito, LEMON WAY se
                    asegurará de que no se realice ninguna transacción en los activos y procederá
                    a cerrar la Cuenta. Si los activos que LEMON WAY retiene en nombre del difunto
                    son mayores que los honorarios en concepto de gastos de Retirada, podrán, no
                    obstante lo dispuesto en el artículo 17 de las presentes Condiciones Generales
                    de Uso, hacer una retirada a favor de los beneficiarios solo después de que
                    estos beneficiarios o su representante legal proporcionen los documentos
                    justificativos, de acuerdo con la legislación aplicable, estableciendo la
                    devolución de la propiedad, además de cualquier otro documento que LEMON WAY
                    crea necesario. Si la transferencia no se puede realizar por cualquier razón,
                    incluyendo la ausencia de la entrega de los documentos de prueba a LEMON WAY,
                    las disposiciones del artículo 13 de las presentes Condiciones Generales de
                    Uso se aplicarán a la prestación.
                  </p>
                  
                  <h3>
                    22- DISPOSICIONES ESPECÍFICAS APLICABLES A CLIENTES COMO PERSONAS JURIDICAS
                  </h3>
                  <p>
                    En caso de que la tasa de fraude en la tarjeta de crédito o cualquier otro
                    método de pago utilizado por los contribuyentes, sea mayor al 0,2% de media en
                    relación con la cantidad de pagos mensuales, LEMON WAY se reserva el derecho
                    de aplicar sanciones. Los pagos no están garantizados por LEMON WAY. El
                    Cliente como persona jurídicas debe cumplir las reglas de VISA y MASTERCARD en
                    particular en lo que respecta a su actividad. Se entregará una declaración de
                    la actividad y formulario de evaluación de riesgos al Cliente como persona
                    física con la que inician relaciones, que deberá ser firmada por el Cliente.
                    En todos los casos, la actividad debe ser legal y confirmar la naturaleza de
                    la actividad declarada en dicha forma. Las actividades, incluso aquellas
                    actividades legales, no aceptadas por LEMON WAY, se incluyen en dicho
                    formulario. LEMON WAY devolverá transacciones rechazadas, como ChargeBack por
                    ejemplo, y otras operaciones que no se puedan realizar con tarjeta VISA o
                    MASTERCARD, cargándolas a la Cuenta de pago de personas jurídicas.
                  </p>
                  <h3>23- GENERALIDADES</h3>
                  <p>
                    Cuando se requieran trámites administrativos para la ejecución de las
                    presentes Condiciones Generales de Uso, LEMON WAY y el Cliente se facilitarán
                    asistencia mutua en la resolución de estos trámites. Si una de las cláusulas
                    de las Condiciones Generales de Servicio resulta ser nula con respecto a una
                    ley vigente o una decisión jurídica que haya adquirido firmeza, se considerará
                    no escrita, pero no implicará la nulidad de estas Condiciones Generales de
                    Servicio. El hecho de que una de las partes actúe en base a la negligencia de
                    la otra parte en una de las obligaciones destinadas en las actuales, no podrá
                    interpretarse en el futuro como renuncia a la obligación en cuestión. En caso
                    de dificultad de interpretación en algunos de los títulos que aparecen como
                    título de las cláusulas de las Condiciones Generales de Servicio, estos
                    títulos no serán considerados. El Cliente podrá dar poder a una persona para
                    utilizar su Cuenta de Pago y asumir plena responsabilidad de las Transacciones
                    de Pago tal como se define en poder notarial. El formulario está disponible en
                    línea y debe devolverse a LEMON WAY. El poder notarial no entrará en vigor
                    hasta que el formulario mencionado se haya rellenado y recibido, y está sujeto
                    a la aceptación por parte de LEMON WAY. Se podrá confirmar por cualquier
                    medio. Esto termina automáticamente después de la muerte del Cliente. Puede
                    ser revocado por iniciativa del Cliente al informar a su representante legal y
                    a LEMON WAY por correo certificado con acuse de recibo. La cancelación tendrá
                    efecto en la fecha de recepción de la cancelación por parte de LEMON WAY. El
                    Cliente sigue siendo responsable de las Operaciones de Pago iniciadas desde su
                    cuenta por el representante legal designado hasta esta fecha. El Cliente
                    libera expresamente a LEMON WAY del secreto profesional en relación con los
                    datos de la Cuenta de Pago con respecto al representante legal designado por
                    poder notarial.
                  </p>
                  <h3>24- LEY APLICABLE Y JURISDICCIÓN</h3>
                  <p>
                    Las presentes Condiciones Generales de Uso se rigen por la ley francesa. Salvo
                    disposición contraria, cualquier disputa relativa a su ejecución,
                    interpretación o validez, deberá ser llevada ante los tribunales que tengan
                    jurisdicción en París.
                  </p>
                </div>              
              </div>
          </div>
      </div>
  </div>
</div>

