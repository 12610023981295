
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'], 
})
export class SpinnerComponent implements OnInit, OnDestroy {
  public message: any = "";
  [x: string]: any;
  public loading: any = false;
  loadingSubscription!: Subscription;

 /*  isLoading = this.spinnerSvc.loading */
  constructor(
    public spinnerService: SpinnerService,   
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.message = data
      
  }
  ngOnInit(): void {
    this.loadingSubscription = this.spinnerService.loadingStatus.subscribe((value) =>{
      this.loading = value;
    });
  }
  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }

  public sendMessage(message: string) {
    this.message = message
  }

}
