import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { SEOService } from './core/services/SEO/seoservice.service';
import { concatMap, filter, map, mergeMap } from "rxjs/operators";
import { UserDataService } from './core/services/user/userData.service';
import { UserService } from './core/services/user/user.service';
import { AffiliateService } from './core/services/affiliate/affiliate.service';
import { of } from 'rxjs';
import { getFase } from './core/extensions/status.extensions';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ZendeskService } from './core/services/zendesk/zendesk.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public hiddenHeader: boolean = false;
  public foo: boolean = false;
  public userType: string;
  public afilliateType: boolean = false;
  public borrowerType: boolean = false;
  _login: boolean;
  _userId: string;
  _userHash: string;
  public onboardingService: any;
  public currentRoute: any;
  public headerOnLanding: boolean = false;
  public enabledPaths: any;
  public name!: string;
  public affiliatePath: string[];
  public creditList: any[];
  public activeCredit: any;
  public belenderActive: string = ''
  public isAppUser: boolean;
  public appCreditId: string // id del prestatario si se accede desde PDV
  public canUseApp: boolean = false;


  constructor(
    private auth: AuthService,
    private router: Router,
    private seoService: SEOService,
    private _route: ActivatedRoute,
    private zendesk: ZendeskService,
    private userDataService: UserDataService,
    private userService: UserService,
    private affiliateService: AffiliateService,
  ) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),

      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)

    )
      .subscribe((event: any) => {

        this.seoService.updateTitle(event['title']);
        this.seoService.updateOgUrl(event['ogUrl']);
        this.seoService.updateDescription(event['description'])
        this.seoService.updateKeywords(event['keywords'])
        if (event['noIndex'] === true) {
          this.seoService.addNoIndex();
        }
      });
  }
  hasTouchSupport(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  isMobileDevice(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  ngOnInit(): void {
  
    if (localStorage.getItem('isAppUser')) {
      this.isAppUser = true;
    }
    this._route.queryParams.subscribe(param => {

      if (param['jwt']) {
        this.isAppUser = true;
        localStorage.setItem('isAppUser', 'true')
        this._userId = param['user'];
        this.appCreditId = param["id"]
        this.auth.setSessionLogged(param['jwt'])
        this.userData();
  
      }

    })
    this.checkExpiry();
    this.userDataService.getUserType().subscribe(
      res => {
        this.userType = res;
        if (this.userType === 'afiliado') {
          this.afilliateType = true;
          this.borrowerType = false;

        } else if (this.userType === 'prestatario') {
          this.borrowerType = true;
          this.afilliateType = false;
        }

      })

    this.setSession()

    //scrollToTop para que al cargar rutas lo haga desde arriba
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      const scrollToTop = window.setInterval(() => {
        const position = window.pageYOffset;
        if (position > 0) {
          window.scrollTo(0, 0);
        } else {
          window.clearInterval(scrollToTop);
        }
      });
    });
  }
  checkExpiry() {
    const dateNow = new Date(Date.now())
    const now = dateNow.getTime()
    if (JSON.stringify(now) > localStorage.getItem('expiry')) {
      localStorage.clear();
    }
  }


  setSession() {
    this.auth.setTokenSessionStorage();
    this.auth.getTokenSessionStorage().subscribe(
      res => {
        this._login = res;
        if (this._login) {
          this.hiddenHeader = true;
          const isMobile = this.hasTouchSupport() && this.isMobileDevice();
          if(localStorage.getItem('appEnabled') && localStorage.getItem('appEnabled') === 'true' && isMobile){
            this.canUseApp = true;
          }
         
        } else {
          this.hiddenHeader = false;
         /*  this.closeZendesk(); */
        }
      })

    this.userDataService.getNameSession().subscribe(
      res => {
        this.name = res
      }
    )

  }

  userData() {
    this.userDataService.setUserType('');
    this.userDataService.setNameSession('');

    this.userService.getUser(this._userId).pipe(
      concatMap((res) => {
        localStorage.setItem('userType', res.tipo);
        this.userDataService.setUserType(res.tipo);
        localStorage.setItem('userId', this._userId);
        localStorage.setItem('userName', res.firstname);

        this.userType = localStorage.getItem('userType');

        if (this.userType === 'prestatario') {
          this.userDataService.setNameSession(res.firstname);
          this.affiliatePath = res.afiliado?.split('/');
          const affiliateId = this.affiliatePath?.[this.affiliatePath?.length - 1];
          localStorage.setItem('affiliateId', affiliateId);
        } else {
          return of(null); // Emite un valor nulo para que el siguiente operador reciba algo
        }
        return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'));
      })
    ).subscribe((data) => {
      localStorage.setItem('financedRate', data.financedRate);
      if (this.userType != 'prestatario') {
        this.userDataService.setNameSession(data.corporationName);
      }
      this.userDataService.setUserDataInStorage(data)
      this.router.navigate([`/prestatario`], { queryParams: { id: this.appCreditId } })


    }, err => {
      /* console.log(err) */
    });
  }

}