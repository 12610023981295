import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class PublicGuard  {
  constructor(private auth: AuthService, private router: Router, private dialog: MatDialog){

  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (!this.auth.isAuthenticated()) {
        return true;
      } else {
        const userType = localStorage.getItem('userType')
        if(userType == 'afiliado'){
          this.router.navigate(['/afiliado'])
          return false;
        }else if(userType == 'prestatario'){
          this.router.navigate(['/prestatario'])
          return false;
        }else{
          return false
        }
      }
  }

  
}
