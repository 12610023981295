<div class="content">
    <div class="form-login">
      <h2>Verifica tu email</h2>
      <div
        class="inputs"
      >
      <p>
        Se ha enviado un correo electrónico a tu cuenta de correo {{userEmail}}. Contiene un enlace de activación al que debes acceder para restablecer tu contraseña. Nota: Solo se puede solicitar una nueva contraseña cada 24 horas. Si no recibes un correo electrónico, comprueba tu carpeta de correo no deseado o inténtalo de nuevo.
    </p>
      </div>
      <div class="buttons">
        <button
        class="main-dark-button"
        routerLink="/"
      >
        <p>Volver a inicio</p>
      </button>
    </div>
    </div>
  </div>
  