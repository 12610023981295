import { Router } from '@angular/router';
import { AffiliateService } from './../../../core/services/affiliate/affiliate.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserDataService } from 'src/app/core/services/user/userData.service';

@Component({
  selector: 'app-header-loggin-in',
  animations: [
    trigger('openCloseBurgerMenu', [
      state('top', style({
        top: '{{topValue}}',
        opacity: 1,
      }), { params: { topValue: '56px' } }),
      state('bottom', style({
        top: '-700px',
        opacity: 0.5
      })),
      transition('top => bottom', [
        animate('0.5s')
      ]),
      transition('bottom => top', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './header-loggin-in.component.html',
  styleUrls: ['./header-loggin-in.component.scss']
})
export class HeaderLogginInComponent implements OnInit, OnChanges {
  public hiddenNav: boolean = true;
  public visible: boolean = false;
  public panelOpenState = false;
  public footerMenu: boolean = false;
  public idBorrower: string;
  breakpoint: number = 768;
  isOpen = false;
  public userType : string;
  public afilliateType: boolean = false;
  public borrowerType:boolean = false;
  public refreshPaths: any;
public enabledPaths: any;
  @Input() name: string;
/*   @Input() recalculatePosition!: boolean; */

  toggle() {
    this.isOpen = !this.isOpen;
  }
  
  constructor( private auth: AuthService, private affiliateData: AffiliateService, private router: Router, private userDataService: UserDataService ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.enabledPaths = {
      linksEnabled: localStorage.getItem('links'),
      simuladorEnabled: localStorage.getItem('simulador'),
      reportsEnabled: localStorage.getItem('reports'),
      usuariosEnabled: localStorage.getItem('usuarios'),
      cobrosEnabled: localStorage.getItem('cobrosEnabled'),
    }
  }
  get topValue(): string {
    return /* this.recalculatePosition ? '150px' : */ '56px';
  }
  
  ngOnInit(): void {

    const w = window.innerWidth;
    if (w <= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
   
    this.userDataService.getUserType().subscribe(
      res => {
        res ? this.userType = res : this.userType = '';
        if(this.userType === 'afiliado'){
          this.afilliateType = true;
          this.borrowerType = false;
        } else if (this.userType === 'prestatario'){
          this.borrowerType = true;
          this.afilliateType = false;
        }
      }
    )      

  }



  navigateTo(){
    if(this.userType == 'prestatario'){
      this.router.navigate(['/prestatario'], { queryParams: { id: localStorage.getItem('idBorrower') }})
    }else if(this.userType == 'afiliado'){
      this.router.navigate(['afiliado'])
    }
  }
  showLogin(){
    this.hiddenNav = false;
  }
  hideLogin(){
    this.hiddenNav = true;
  }
  showFooter(){
    this.footerMenu = true;
  }
  hideFooter(){
    this.footerMenu = false;
  }
  logout(){ 
    this.auth.logout()
/*     window.location.reload() */
  }

  onResize(event:any) {
    const w = event.target.innerWidth;
    if (w <= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  
  
}
