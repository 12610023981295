import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { configureZendeskWidget, loadZendeskWidgetScriptConfia, loadZendeskWidgetScriptZank } from 'src/zendesk-config';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  public userType: string;
  public confiaZendeskId: string = '2af79192-7deb-48d6-afe9-65b00eb54de0';
  public zankZendeskId: string = '6c50da89-b956-441d-8386-a5ca474c8ccb';
  

  constructor( private router: Router) { 
    //Logica para suscribirte a la ruta y ver que si se cumple la condición cargue el zendesk y sino lo oculte.
      
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.userType = localStorage.getItem('userType')
        if ((this.userType === 'afiliado' || this.userType === 'comercial') && (this.router.url.includes('afiliado') || this.router.url.includes('preferencias'))) {
          this.loadZendesk(this.confiaZendeskId);
        } else {
          this.removeZendesk();
        }
      });
    
  }

  //Función para cargar de del chat de zendesk segun su id
  loadZendesk(accountKey: string): void {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${accountKey}`;
    document.body.appendChild(script);
    script.onload = () => {
      window.zE('webWidget', 'show');
    };
  }

  //Función para ocultar el chat de zendesk
    removeZendesk(): void {
      if (window.zE) {
        window.zE('webWidget', 'hide');
      }
    }

  //Función para cambiar los estilos del zendesk
  configureZendeskWidget() {
    window.zESettings = {
       webWidget: {
        color: {
          theme: '#009bae'
        },
        offset: {
          horizontal: '100px',
          vertical: '100px'
        }
      } 
    };
  
    // Aquí puedes agregar más configuraciones y comandos según tus necesidades
    window.zE('webWidget', 'setLocale', 'es'); 
  }
}
