import { SharedModule } from './shared';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RessetingPassComponent } from './modules/resseting-pass/resseting-pass.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AuthGuard } from './core/services/guards/auth.guard';
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service';
import { CheckEmailComponent } from './modules/resseting-pass/check-email/check-email.component';
import { ResetFormComponent } from './modules/resseting-pass/reset-form/reset-form.component';
import { JwtInterceptorService } from './core/interceptors/jwt-interceptor.service';
import { UserTypeGuard } from './core/services/guards/userType.guard';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';
import { LoginAdminComponent } from './modules/login-admin/login-admin.component';
import { MaintenanceGuard } from './core/services/guards/maintenance.guard';
import { UserComercialGuard } from './core/services/guards/userComercial.guard';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './core/services/customMatPaginatorIntl';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';


registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    RessetingPassComponent,
    CheckEmailComponent,
    ResetFormComponent,
    MaintenanceComponent,
    LoginAdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgChartsModule,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    
  ],
  providers: [
    AuthGuard,
    UserTypeGuard,
    MaintenanceGuard,
    UserComercialGuard,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true
  },
  {
    provide:MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
