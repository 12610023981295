import { Component, OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared';

@Component({
    selector: 'app-payment-id-conditions',
    templateUrl: './payment-id-conditions.component.html',
    styleUrls: ['./payment-id-conditions.component.scss'],
    standalone: true,
    imports: [SharedModule]
})
export class PaymentIdConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
