import { DocumentsBorrowerComponent } from './modules/borrower/documents-borrower/documents-borrower.component';
import { LoginVTFComponent } from './modules/affiliate/loginVTF/login-vtf/login-vtf.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CookiesInfoComponent } from './modules/legal-info/cookies-info/cookies-info.component';
import { GeneralConditionsComponent } from './modules/legal-info/general-conditions/general-conditions.component';
import { PaymentIdConditionsComponent } from './modules/legal-info/payment-id-conditions/payment-id-conditions.component';
import { PrivacyPolicyComponent } from './modules/legal-info/privacy-policy/privacy-policy.component';
import { RessetingPassComponent } from './modules/resseting-pass/resseting-pass.component';
import { AuthGuard } from './core/services/guards/auth.guard';
import { CheckEmailComponent } from './modules/resseting-pass/check-email/check-email.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { ResetFormComponent } from './modules/resseting-pass/reset-form/reset-form.component';
import { UserTypeGuard } from './core/services/guards/userType.guard';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';
import { LoginAdminComponent } from './modules/login-admin/login-admin.component';
import { MaintenanceGuard } from './core/services/guards/maintenance.guard';
import { UserComercialGuard } from './core/services/guards/userComercial.guard';
import { PublicGuard } from './core/services/guards/public.guard';
import { HelpComponent } from './shared/components/help/help.component';


const routes: Routes = [
   {
    path: '', loadChildren: () => import("./modules/landing/landing.module").then(m => m.LandingModule),
    canActivate:[PublicGuard],
    data:{
      title: 'Home',
      description: 'Financiación en Confia',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'afiliado',
    loadChildren: () => import("./modules/affiliate/affiliate.module").then(m => m.AffiliateModule),
    canActivate: [AuthGuard, UserTypeGuard, MaintenanceGuard],
    data:{ 
      title: 'Afiliado',
      description: 'Incrementa tus ventas ofreciendo nuevas formas de financiación a tus clientes.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    },
  },
  {
    path: 'condiciones-uso', loadChildren: () => import("./modules/legal-info/legal-info.module").then(m => m.LegalInfoModule),
    data:{
      title: 'Condiciones de uso',
      description: 'Dale un vistazo a las condiciones de uso de Confia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'ayuda', component: HelpComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title: 'Ayuda'
    }
  },
  {
    path: 'documentos', loadChildren: () => import("./modules/affiliate/documents/documents.module").then(m => m.DocumentsModule),
    canActivate: [AuthGuard,UserComercialGuard, MaintenanceGuard],
    data:{
      title: 'Documentos'
    }
  },
  {
    path: 'prestatario', loadChildren: () => import("./modules/borrower/borrower.module").then(m => m.BorrowerModule),
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title:'Prestatario'
    }
  },
  {
    path: 'subida-documentos',component: DocumentsBorrowerComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title: localStorage.getItem('financingCode') ? localStorage.getItem('financingCode') : 'Subida de documentos'
    }
  },
  { 
    path:'condiciones-generales', component: GeneralConditionsComponent,
    data:{
      title: 'Condiciones generales',
      description: 'Consulta nuestras condiciones generales para enterarte bien de como funciona Confia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    } 
  },
  { 
    path:'condiciones-entidad-pago', component: PaymentIdConditionsComponent,
    data:{
      title: 'Condiciones entidad pago',
      description: 'Utilizamos la entidad de pago certificada Lemon Way, estas son sus condiciones.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }  
  },
  { 
    path:'politica-privacidad', component: PrivacyPolicyComponent,
    data:{
      title: 'Política de privacidad',
      description: 'Dale un vistazo a la política de privacidad de Confia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }   
  },
  {
     path:'cookies', component: CookiesInfoComponent,
     data:{
      title: 'Cookies',
      description: 'Dale un vistazo a la política de cookies de Confia.'
    }    
  },
  {
    path:'resseting/request', component: RessetingPassComponent,
    data:{
      title: 'Cambiar contraseña/email'
    } 
  },
  { 
    path: 'resetting/check-email', component: CheckEmailComponent,
    data:{
      title: 'Cambiar email'
    } 
  },
  { 
    path: 'reset-password', component: ResetFormComponent,
    data:{
      title: 'Cambiar contraseña'
    } 
  },
  {
    path: 'profile/change-password', component: ChangePasswordComponent,
    data:{
      title: 'Perfil/Cambio de contraseña'
    }
  },
 // { path: 'table', loadChildren: () => import('./shared/components/table/table.module').then(m => m.TableModule) },

  {
    path: 'VTF/:hash/:id', component: LoginVTFComponent,
    data:{
      title: 'Confia'
    }
  },
  {
    path: 'cu/:id/:token', component: LoginAdminComponent,
    data:{
      title: 'Login'
    }
  },
  { 
    path:'mantenimiento', component: MaintenanceComponent,
    data:{
      title: 'Mantenimiento',
      description: 'Mantenimiento',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }   
  },
  {
    path: '**', redirectTo:'', pathMatch: 'full'
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes,  { scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
