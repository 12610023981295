<div class="contact-section">
  <div>
    <img src="../../../../../assets/finance/ICO-Movil-Confia-01.svg" alt="" />
  </div>
  <div class="contact-text">
    <p class="contact-title">¿Prefieres que te llamemos?</p>
    <button  *ngIf="!formOpened" mat-flat-button color="primary" (click)="toggle()">
      Solicitar llamada
    </button>
    <button  *ngIf="formOpened" mat-flat-button color="primary" (click)="toggle()">
      Cerrar
    </button>
    <div class="form-inputs" *ngIf="formOpened" [@visibleHidden]=" isVisible? 'visible' : 'hidden'">
      <form  [formGroup]="contactForm" novalidate>
        <mat-form-field appearance="fill">
            <input  placeholder="Email*" matInput formControlName="email" #email type="email">
            <mat-error *ngIf="!contactForm.get('email')?.valid && (contactForm.get('email')?.dirty)">Introduce un email correcto</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <input placeholder="Teléfono*" matInput formControlName="phoneNumber" #telefono type="phone">
            <mat-error *ngIf="!contactForm.get('phoneNumber')?.valid && (contactForm.get('phoneNumber')?.dirty)">Instroduce un número de teléfono correcto</mat-error>
        </mat-form-field>
      <div>
        <button mat-flat-button color="primary" [disabled]="!contactForm?.valid" (click)="onSubmit()">ENVIAR </button>
      </div>
    </form>
<!--     <p *ngIf="tokenVisible">{{reCAPTCHAToken}}</p> -->
    
    </div>
    <app-toolbar-alerts class="app-toolbar" *ngIf="status" [alertMessage]="alertMessage" [status]="status"></app-toolbar-alerts>
  </div>
</div>