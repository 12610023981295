import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { SEOService } from './core/services/SEO/seoservice.service';
import { UserDataService } from './core/services/user/userData.service';
import { ZendeskService } from './core/services/zendesk/zendesk.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public userType: string;
  public name!: string;
  public isLoading: boolean = true;
  public isLoginVisible: boolean = true;
  public burgerMenuActive!: boolean;
  public breakpoint = 1024;
  _login: boolean;
  _userId: string;
  _userHash: string;

  public isVisiblesideNav$: boolean;
  /* public isAppUser: boolean; 
  public appCreditId: string // id del prestatario si se accede desde PDV
  public canUseApp: boolean = false;
 */

  constructor(
    private auth: AuthService,
    private router: Router,
    private seoService: SEOService,
    private _route: ActivatedRoute,
    private userDataService: UserDataService,
    private breakpointObserver: BreakpointObserver,
    private zendesk: ZendeskService,

  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),

        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        this.seoService.updateTitle(event['title']);
        this.seoService.updateOgUrl(event['ogUrl']);
        this.seoService.updateDescription(event['description']);
        this.seoService.updateKeywords(event['keywords']);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0; // Para Safari
        if (event['noIndex'] === true) {
          this.seoService.addNoIndex();
        }
      });
  }

  ngOnInit(): void {
      this.loadRecaptchaScript();
    this.checkExpiry(); //? ESTO SOLO SE EJECUTA UNA VEZ???
    this.setSession();
    this.observerBreakPoint();
  }

  observerBreakPoint(){
    this.breakpointObserver
    .observe([`(max-width:${this.breakpoint}px)`])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.burgerMenu(true);
      } else {
        this.burgerMenu(false);
      }
    });
  }

  private loadRecaptchaScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptcha.siteKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  
  checkExpiry() {
    const dateNow = new Date(Date.now());
    const now = dateNow.getTime();
    if (JSON.stringify(now) > localStorage.getItem('expiry')) {
      localStorage.clear();
    }
  }

  burgerMenu(burger: boolean) {
    this.burgerMenuActive = burger;
  }

  receiveOpenedSideNav(val: boolean) {
    if (val) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }

  setSession() {
    this.auth.setTokenSessionStorage();
    this.observerBreakPoint()
    this.userDataService.getNameSession().subscribe((res) => {
      this.name = res;
      this.userType = localStorage.getItem('userType');
    });
    this.auth.getTokenSessionStorage().subscribe((res) => {
      this._login = res;

      //Set y get affiliateLinks para actualizar el valor de los links
      this.isLoginVisible = false;

      if (this._login) {
        this.isLoggedIn = true;

        //webview
        /*   const isMobile = this.hasTouchSupport() && this.isMobileDevice();
            if(localStorage.getItem('appEnabled') && localStorage.getItem('appEnabled') === 'true' && isMobile){
              this.canUseApp = true;
            } */
      } else {
        /*  this.linksReady = false; */
        this.isLoggedIn = false;
        this.isLoginVisible = true;

        /*  this.closeZendesk(); */
      }
    });
  }

  //webView
  /*   userData() {
      this.userDataService.setUserType('');
      this.userDataService.setNameSession('');
  
      this.userService.getUser(this._userId).pipe(
        concatMap((res) => {
          localStorage.setItem('userType', res.tipo);
          this.userDataService.setUserType(res.tipo);
          localStorage.setItem('userId', this._userId);
          localStorage.setItem('userName', res.firstname);
  
          this.userType = localStorage.getItem('userType');
  
          if (this.userType === 'prestatario') {
            this.userDataService.setNameSession(res.firstname);
            this.affiliatePath = res.afiliado?.split('/');
            const affiliateId = this.affiliatePath?.[this.affiliatePath?.length - 1];
            localStorage.setItem('affiliateId', affiliateId);
          } else {
            return of(null); // Emite un valor nulo para que el siguiente operador reciba algo
          }
          return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'));
        })
      ).subscribe((data) => {
        localStorage.setItem('financedRate', data.financedRate);
        if (this.userType != 'prestatario') {
          this.userDataService.setNameSession(data.corporationName);
        }
        this.userDataService.setUserDataInStorage(data)
        this.router.navigate([`/prestatario`], { queryParams: { id: this.appCreditId } })
  
  
      }, err => {
      });
    }
   */
}
