import { Component, OnInit } from '@angular/core';
import { downloadFile } from '../../../core/extensions/downloadFile';
import { SharedModule } from 'src/app/shared';

@Component({
    selector: 'app-general-conditions',
    templateUrl: './general-conditions.component.html',
    styleUrls: ['./general-conditions.component.scss'],
    standalone: true,
    imports: [SharedModule]
})
export class GeneralConditionsComponent implements OnInit {

  downloadFile = downloadFile

  constructor() { }

  ngOnInit(): void {
  }

  async onDownload(): Promise<void> {
    try {
      const response = await fetch('assets/pdf/Condiciones_generales_CONFIA.pdf');
      if (!response.ok) {
        throw new Error(`Error al cargar el archivo: ${response.statusText}`);
      }
      const blob = await response.blob(); 
      downloadFile(blob, 'Condiciones_generales_CONFIA.pdf');
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  }

}
