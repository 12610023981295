import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmedValidator } from 'src/app/core/extensions/confirmPasword';
import { allRegex } from 'src/app/core/extensions/regex';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
    selector: 'app-reset-form',
    templateUrl: './reset-form.component.html',
    styleUrls: ['./reset-form.component.scss'],
    standalone: false
})
export class ResetFormComponent implements OnInit {

  public resetPasswordForm: FormGroup
  public token : string
  public toolbarHidden : boolean = false
  public status : string;
  public alertMessage: string;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private userService : UserService) {
    this.resetPasswordForm = formBuilder.group({
      newPassword: ['', [Validators.required]], /* , Validators.pattern(allRegex.regexPassword) */
      repeatNewPassword: ['', [Validators.required]]
    },
      {
        validators: ConfirmedValidator('newPassword', 'repeatNewPassword')
      })
  }
  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      if (params){
         // console.log(params); { orderby: "price" }
      this.token= params['token'];
      }
  })
}

  onSubmit() {
    if (this.resetPasswordForm.valid) {
      const data = {
        newPassword: this.resetPasswordForm.get('newPassword').value,
      }
      this.userService.resetPassword(this.token , data.newPassword).subscribe({
        complete: () => {
          this.status = 'ok'
          this.alertMessage = 'Amortización solicitada, te hemos enviado un correo electrónico con todos los detalles.'
          this.resetPasswordForm.reset()
        },
        error: (err) => {
          this.status = 'ko';
          this.alertMessage = 'Hubo un problema cambiando la contraseña'
          this.resetPasswordForm.reset()
        },
        next: (res) => {
  
        }
      })
    }
    
  }

  closeToolbar(){
    this.toolbarHidden = true
  }
}
