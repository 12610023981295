<div class="reg_container">
  <div class="Page_container">
    <div class="reg_content">
      <app-toolbar-alerts
        *ngIf="status"
        [status]="status"
        [alertMessage]="alertMessage"
      ></app-toolbar-alerts>
      <h2>Cambia tu contraseña</h2>
      <div class="forms_container">
        <div class="passwordForm-title">
          <p>INTRODUCIR NUEVA CONTRASEÑA</p>
        </div>
        <div class="passwordForm-body">
          <form
            class="password_form"
            [formGroup]="resetPasswordForm"
            novalidate
            (ngSubmit)="onSubmit()"
          >
            <mat-form-field appearance="fill" color="primary">
              <mat-label color="accent">Nueva contraseña</mat-label>
              <input
                matInput
                formControlName="newPassword"
                #password
                type="password"
              />
              <mat-error *ngIf="!resetPasswordForm.get('newPassword')?.valid"
                >Introduce una contraseña válida</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" color="primary">
              <mat-label color="accent"
                >Vuelve a escribir la nueva contraseña</mat-label
              >
              <input
                matInput
                formControlName="repeatNewPassword"
                #password
                type="password"
              />
              <mat-error
                *ngIf="
                  resetPasswordForm.get('repeatNewPassword')?.value !==
                  resetPasswordForm.get('newPassword')?.value
                "
                >Las contraseñas tienen que coincidir</mat-error
              >
            </mat-form-field>
          </form>
          <button
            mat-stroked-button
            color="primary"
            (click)="onSubmit()"
            aria-label="register_btn"
            type="submit"
            [disabled]="!resetPasswordForm?.valid"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
