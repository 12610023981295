import { getAge } from "./dates.extensions";

export function getUploadIncomeDoc(infoBorrower, secondaryReason, creditData) {
    if (getForceRouter4(secondaryReason)){
        return false;
    } else if (infoBorrower.contractType == 'Autonomo') {
        return true;
    } else if (creditData.cantidad > 6000) {
        return true;
    } else {
        return false;
    }
}
export function getUploadIncomeDocCotitular(cotitular, secondaryReason, creditData) {
    if (getForceRouter4(secondaryReason)){
        return false;
    } else if (cotitular.contractType == 'Autonomo') {
        return true;
    } else if (creditData.cantidad > 6000) {
        return true;
    } else {
        return false;
    }
}

export function getUploadAutoDoc(secondaryReason){
    const autoVO = [17, 62, 63, 66, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77];
    if (secondaryReason == undefined){
        return false;
    } else if (autoVO.includes(secondaryReason.idSecondaryReason)) {
        return true;
    } else {
        return false;
    }
}

export function getMandatoryCHB(secondaryReason) {
    const autoVO = [17, 62, 63, 66, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77];
    if (secondaryReason == undefined){
        return false;
    } else if (autoVO.includes(secondaryReason.idSecondaryReason)) {
        return true;
    } else {
        return false;
    }
}

export function getApplyDE1Rules(secondaryReason, infoBorrower) {
    const autoVO = [17, 62, 63, 66, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77];
    if (secondaryReason == undefined){
        return false;
    } else if (autoVO.includes(secondaryReason.idSecondaryReason)) {
        //if ($this->getCantidad() >= 12000) return true;
        if(getAge(infoBorrower.fechaNacimiento) >= 74) {
            return true;
        }
        //if ($this->getDownpayment()/$this->getCantidad() < 0.10) return true;
        else if (infoBorrower.income == null) {
            return true;
        } else if (infoBorrower.monthlyIncome > (infoBorrower.income * 3)){
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function getForceRouter4(val){
    const autoVO = [17, 62, 63, 66, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77];
    if(val == undefined) {
        return false;
    } else if(autoVO.includes(val.idSecondaryReason)) {
        return true;
    } else {
        return false;
    }
}

