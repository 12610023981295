<div class="content">
  <app-toolbar-alerts
    class="toolbar-container"
    *ngIf="errorAlert"
    [status]="status"
    [alertMessage]="errorMessage"
  ></app-toolbar-alerts>
  <div class="form-login">
    <h2>Cambiar contraseña</h2>
    <form
      class="inputs"
      [formGroup]="emailInput"
      novalidate
      (ngSubmit)="onSubmit()"
    >
      <p>
        Introduce la dirección de email con la que te registraste como usuario.
        Te mandaremos un email a esa misma dirección con un enlace para que
        cambies tu contraseña. <br><br>Si después de una hora no has recibido tu email,
        verifica tu carpeta de spam o
        <a routerLink="/ayuda">contacta con nosotros.</a>
      </p>
      <div class="individual">
        <mat-form-field appearance="outline" color="primary">
            <mat-label color="accent">Email</mat-label>
            <input matInput formControlName="email" #email />
            <mat-icon matPrefix>mail</mat-icon>
            <mat-error
              *ngIf="
                !emailInput.get('email')?.valid &&
                (emailInput.get('email')?.dirty || submitted)
              "
              >Introduce un email correcto</mat-error
            >
          </mat-form-field>
      </div>
    </form>
    <div class="buttons">
        <button
        class="main-dark-button"
        (click)="onSubmit()"
        aria-label="register_btn"
        type="submit"
        [disabled]="!emailInput?.valid"
      >
       <p>Enviar</p> 
      </button>
    </div>
  </div>
</div>
