import { BehaviorSubject, Observable } from 'rxjs'; 
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDataService{

  userType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  name$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
    /* const sub = interval(300).subscribe( () => {
        this.setUserType(localStorage.getItem('userType'))
        this.setNameSession( localStorage.getItem('userType') == 'prestatario' ? localStorage.getItem('userName') : localStorage.getItem('corporationName') )
    })
    if(localStorage.getItem('userType')){
      sub.unsubscribe()
    } */
    this.setUserType(localStorage.getItem('userType'))
    localStorage.getItem('userType') == 'prestatario' ? this.setNameSession(localStorage.getItem('userName'))  : this.setNameSession(localStorage.getItem('corporationName')) 
   }
  
  getNameSession(): Observable<string> { 
    return this.name$.asObservable();
  }

  setNameSession(element: string) {
    this.name$.next(element);
  }

  getUserType(): Observable<string> { 
    return this.userType$.asObservable();
  }

  setUserType(element: string) {
    this.userType$.next(element);
  }

  setUserDataInStorage(data){
    const businessPath = data.datosEmpresa?.split('/');
    const businessId = businessPath?.[businessPath?.length - 1];
    const userPath = data.associatedInvestor?.split('/');
    const userId = userPath?.[userPath?.length - 1];
    const salesAgentPath = data.zankSalesAgent?.split('/');
    const agentId = salesAgentPath?.[salesAgentPath?.length - 1];
    const reportsEnabled = data.reports
    const linksEnbled = data.links
    const simuladorEnabled = data.simulador
    const usuariosEnabled = data.usuarios
    const cobrosEnabled = data.cobrosEnabled
    localStorage.setItem('businessId', businessId);
    localStorage.setItem('estadoCd', data.estadoCd);
    localStorage.setItem('corporationName', data.corporationName);
    localStorage.setItem('datosEmpresa', data.datosEmpresa);
    localStorage.setItem('associatedInvestor', userId);
    localStorage.setItem('salesAgentId', agentId);
    localStorage.setItem('inversores', data.usuarios)
    localStorage.setItem('cobrosEnabled', cobrosEnabled);
    localStorage.setItem('reports', reportsEnabled);
    localStorage.setItem('links', linksEnbled);
    localStorage.setItem('simulador', simuladorEnabled);
    localStorage.setItem('usuarios', usuariosEnabled);
  }
}
