import { TransactionModel, DocumentsModel, SalesAgentsModel, BusinessModel, FinancingModel } from './../../models/Affiliate/affiliate';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AffiliateAbstractService } from './affiliate.abstract-service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService implements AffiliateAbstractService {
  
  constructor( private http: HttpClient) { }

  public getAffiliate(id: string){
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}`)
  }
  public businessData(id: string): Observable<BusinessModel>{
    return this.http.get<BusinessModel>(`${environment.baseUrl}/api/datos_empresas/${id}`)
  }
  public financiacionList(id: string): Observable<FinancingModel>{
    return this.http.get<FinancingModel>(`${environment.baseUrl}/api/afiliados/${id}/financiacionList`)
  }
  public getFinancings(id, page, status, minAmount, maxAmount, minDuration, maxDuration, cobros):Observable<any[]>{
    return this.http.get<any[]>(`${environment.baseUrl}/api/afiliados/${id}/financiacionList?page=${page}&limit=30${status}${minAmount}${maxAmount}${minDuration}${maxDuration}`)
  }
  public transactionList(id: string): Observable<TransactionModel>{
    return this.http.get<TransactionModel>(`${environment.baseUrl}/api/afiliados/${id}/transactionList`)
  }
  public sucursalesList(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/sucursalesList`)
  }
  public salesAgents(id: string): Observable<SalesAgentsModel>{
    return this.http.get<SalesAgentsModel>(`${environment.baseUrl}/api/afiliados/${id}/salesAgentsInDepthList`)
  }
  public dowloadDocument(id: number, responseType:any, authorization?: any): Observable<any>{
    return this.http.get<Blob>(`${environment.baseUrl}/api/document/download/${id}`, {responseType: responseType, headers: authorization})
  }
  public productList(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/productList`)
  }
  public postLink(body: any): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}/api/links_afiliados`, body)
  }
  public deleteLink(id: string): Observable<any>{
    return this.http.delete<any>(`${environment.baseUrl}/api/links_afiliados/${id}`)
  }
  public documentsList(id: string): Observable<DocumentsModel>{
    return this.http.get<DocumentsModel>(`${environment.baseUrl}/api/afiliados/${id}/documentsList`)
  }
  public uploadFile(doc: any): Observable<DocumentsModel>{ 
   return this.http.post<DocumentsModel>(`${environment.baseUrl}/api/uploadDocument`, doc)
  }
  public cobrosGraph(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/cobrosGraph`)
  }
  public getSecondaryReason(id: string):Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/secondaryReasonsList`)
  }
  public getConfigs(id: string):Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/configuraciones_afiliados`)
  }
  public getCobrosExcel(id: string, responseType:any,  headers:any) : Observable<any>{
    return this.http.get<Blob>(`${environment.baseUrl}/api/afiliados/${id}/cobrosGraph/excel`, {
       responseType,  headers});
  }
  public getCobrosExcelDetail(id: string, responseType:any,  headers:any) : Observable<any>{
    return this.http.get<Blob>(`${environment.baseUrl}/api/afiliados/${id}/cobrosGraphDetail/excel`, {
       responseType: responseType,  headers :headers});
  }
  public getSalesAgent(id: string):Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/zank_salesforces/${id}`)
  }
  public getInsurances(): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/insurances`);
  }
  public getFinancingDetail(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/creditos/${id}`);
  }
  public getFinancingStatusHistory(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/creditos/${id}/statusHistoryList`);
  }
  public getInfoPrestatario(link: string):Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}${link}`)
  }
  public getDatosEmpresa(link: string):Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}${link}`)
  }
  public getGraphData(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/clientsGraphData`);
  }
/*   public postSeller(seller:any){
    return this.http.post<any>(`${environment.baseUrl}/api/users`, seller)
  }
  public updateSeller(id:string, seller:any){
    return this.http.put<any>(`${environment.baseUrl}/api/users/${id}`, seller)
  } */

  public postSeller(affiliateId: string, body: any){
    return this.http.post<any>(`${environment.baseUrl}/api/afiliados/${affiliateId}/createSalesAgent`, body)
  }
  
  public postLoan(body:string){
    return this.http.post<any>(`${environment.baseUrl}/api/createLoan`, body)
  }
  public getLoanListExcel(id:string, startDate:string, endDate:string, responseType:any,  headers:any): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/loanList/excel?start=${startDate}&end=${endDate}`, {
      responseType,  headers});
  }
  public getAprovedLoansExcel(id:string, startDate:string, endDate:string, responseType:any,  headers:any): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/afiliados/${id}/aprovedLoans/excel?start=${startDate}&end=${endDate}`, {
      responseType,  headers});
  }
  public getValidateSimulator(key:string, value: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/checkExistingLoan?${key}=${value}`);
  }
  public validateAllFieldSimulator(email:string, phone: string, dni: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/checkExistingLoan?email=${email}&phone=${phone}}&dni=${dni}`);
  }

  public updateAffiliate(id: string, body):Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/afiliados/${id}`, body)
  }

}
