import { UserService } from 'src/app/core/services/user/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { SEOService } from 'src/app/core/services/SEO/seoservice.service';
import { UserDataService } from 'src/app/core/services/user/userData.service';
import { catchError, concatMap, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-login-vtf',
  templateUrl: './login-vtf.component.html',
  styleUrls: ['./login-vtf.component.scss']
})
export class LoginVTFComponent implements OnInit {
  _userId: string;
  _userHash: string;
  public userType: string;
  public affiliatePath: string[];
  public creditList: any[];
  public activeCredit: any;
  public belenderActive: string = ''
  public isAppEnabled: boolean = false;
  constructor(
    private vtfService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private affiliateService: AffiliateService,
    private userDataService: UserDataService
    ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this._userId = params.get("id");
      this._userHash = params.get("hash");
      if(this.router.url.includes('belender')){
        this.belenderActive = '?belender=1'
      }
    })

    this.route.queryParams.subscribe((params) => {
      this.activeCredit = params['id']
    })
   
    this.vtfService.getToken(this._userId, this._userHash, this.belenderActive).subscribe(
      res => {
        this.setSession(res.token);
        this.userData();
      },
      err => {
      }
    )
  }

  public getIdFromPath(path: string): string {

    const segments = path?.split("/");
    return segments?.[segments.length - 1];
  }


  userData() {
    this.userDataService.setUserType('');
    this.userDataService.setNameSession('');
  
    this.userService.getUser(this._userId).pipe(
      tap(res => {
        localStorage.setItem('userType', res.tipo);
        localStorage.setItem('userId', this._userId);
        localStorage.setItem('userName', res.firstname);
        this.userType = localStorage.getItem('userType')!;
        this.userDataService.setUserType(res.tipo);
      }),
      concatMap(res => {
        if (this.userType === 'prestatario') {
          this.userDataService.setNameSession(res.firstname);
          if (res.afiliado) {
            localStorage.setItem('affiliateId', this.getIdFromPath(res.afiliado));
            return this.userService.getCreditList(this._userId);
          } else {
            this.router.navigate(['/prestatario/inactivo']);
            return of(null);
          }
        } else {
          if (res.afiliado) {
            localStorage.setItem('affiliateId', this.getIdFromPath(res.afiliado));
          }
          return of(null);
        }
      }),
      switchMap(() => {
        const affiliateId = localStorage.getItem('affiliateId');
        if (affiliateId) {
          return this.affiliateService.getAffiliate(affiliateId);
        } else {
          return of(null); 
        }
      }),
      tap(data => {
        if (data) {
          localStorage.setItem('financedRate', data.financedRate);
          if (this.userType !== 'prestatario') {
            this.userDataService.setNameSession(data.corporationName);
          }
  
          localStorage.setItem('businessId', this.getIdFromPath(data.datosEmpresa));
          localStorage.setItem('estadoCd', data.estadoCd);
          localStorage.setItem('corporationName', data.corporationName);
          localStorage.setItem('datosEmpresa', data.datosEmpresa);
          localStorage.setItem('associatedInvestor', this.getIdFromPath(data.associatedInvestor));
          localStorage.setItem('salesAgentId', this.getIdFromPath(data.zankSalesAgent));
          localStorage.setItem('inversores', data.usuarios);
          localStorage.setItem('cobrosEnabled', data.cobrosEnabled);
          localStorage.setItem('reports', data.reports);
          localStorage.setItem('links', data.links);
          localStorage.setItem('simulador', data.simulador);
          localStorage.setItem('usuarios', data.usuarios);
        }

        if (this.userType === 'prestatario') {
          this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCredit } });
        } else {
          this.router.navigate(['/afiliado']);
        }
      }),
      catchError(err => {
        console.error('Error en el proceso de obtención de datos:', err);
        return of(null);
      })
    ).subscribe();
  }

  setSession(token: string) {
    if (token != '')
    this.vtfService.setSessionLogged(token);
  }

  logout() {
    return this.vtfService.logout();
  }

}
