<div class="reg_container">
    <div class="Page_container">
        <div class="reg_content">
            <div class="title_content">          
                <h2>Todas las normas <br> por las que nos regimos.</h2>               
            </div>
            <div class="columns">
                <div class="forms_container">
                    <div class="generalConditions">
                        <h2>Condiciones generales</h2>
                        <p (click)="onDownload()">Descargar condiciones generales</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-landing></app-footer-landing>