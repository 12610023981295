<div class="reg_container">
    <div class="Page_container">
        <img src="../../../../assets/img/logo azul.png" alt="logo_confia">
        <mat-divider></mat-divider>
        <div class="reg_content">
              <app-toolbar-alerts class="toolbar-container" *ngIf="errorAlert" [status]="status" [alertMessage]="errorMessage"></app-toolbar-alerts>
            <div class="columns">
                <h2>Cambiar Contraseña</h2>
                <div class="forms_container">                 
                    <div class="email_input">
                        <p>
                            Introduce la dirección de email con la que te registraste como usuario. Te mandaremos un email a esa misma dirección con un enlace para que cambies tu contraseña.
                            Si después de una hora no has recibido tu email, verifica tu carpeta de spam o <a href="contactanos">contacta con nosotros.</a>
                        </p>
                        <form class="email_form" [formGroup]="emailInput" novalidate (ngSubmit)="onSubmit()">
                            <mat-form-field appearance="fill" color="primary">
                                <mat-label color="accent">Email</mat-label>
                                <input matInput formControlName="email" #email>
                                <mat-error *ngIf="!emailInput.get('email')?.valid && (emailInput.get('email')?.dirty || submitted)">Introduce un email correcto</mat-error>
                            </mat-form-field>
                        </form>
                        
                    </div>
                    <div class="email-button">
                        <button mat-stroked-button color="primary" (click)="onSubmit()" aria-label="register_btn" type="submit" [disabled]="!emailInput?.valid">
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
