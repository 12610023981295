<!-- CODIGO ANTES DE REDISEÑO -->
<!-- <div class="app-bar" *ngIf="canUseApp">
    <p>¿Prefieres usar la aplicación de Confia?</p>
    <button mat-flat-button color="accent">Abir App</button>
</div> -->
<!-- <ng-container *ngIf="!isAppUser">
    <app-header-main *ngIf="!isLoggedIn" ></app-header-main>
    <app-header-loggin-in *ngIf="isLoggedIn" [enabledPaths]="enabledPaths"
        [name]="name"></app-header-loggin-in>
    <div [ngClass]="{
    'affiliateMode':afilliateType,
    'borrowerMode':borrowerType,
    'landingMode':!afilliateType && !borrowerType
}">
        <router-outlet><app-spinner></app-spinner></router-outlet>
    </div> -->
<!-- <app-footer-landing [isLoggedIn]="isLoggedIn"></app-footer-landing> -->
<!-- </ng-container> -->

<!-- WEBVIEW -->
<!-- <ng-container *ngIf="isAppUser">
    <div class="appRenderMode">
        <router-outlet></router-outlet>
    </div>
</ng-container> -->

<ng-container>
  <app-header-public
    (openedEvent)="receiveOpenedSideNav($event)"
    [isLoggedIn]="isLoggedIn"
    [name]="name"
    [burgerMenuActive]="burgerMenuActive"
  ></app-header-public>
  @if(isLoggedIn && !burgerMenuActive){
  <app-header-private-desktop></app-header-private-desktop>
  } @if(isLoggedIn && burgerMenuActive){
  <app-header-private-burger></app-header-private-burger>
  }

  <div
    class="router"
    [ngClass]="{
      'router-borrower': isLoggedIn && userType === 'prestatario',
      'router-affiliate': isLoggedIn && userType === 'afiliado',
      'router-out': !isLoggedIn || (isLoggedIn && userType == null),
      'no-sidenav-active': burgerMenuActive
    }"
  >
    <!--   <div *ngIf="!burgerMenuActive && isVisiblesideNav$" class="sidenav">
      <app-sidenav
        [linksEnabled]="linksEnabled"
      ></app-sidenav>
    </div> -->

    <div
      class="router-outlet"
      [ngClass]="{
        'with-sidenav': isLoggedIn && !burgerMenuActive,
        'router-landing': !isLoggedIn
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
