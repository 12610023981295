import { SpinnerService } from './../../../core/services/spinner/spinner.service';
import { UserService } from './../../../core/services/user/user.service';
import { AffiliateModel } from './../../../core/models/Affiliate/affiliate';
import { Component, OnInit } from '@angular/core';
import {Router } from '@angular/router';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';

@Component({
  selector: 'app-header-affiliate',
  templateUrl: './header-affiliate.component.html',
  styleUrls: ['./header-affiliate.component.scss']
})
export class HeaderAffiliateComponent implements OnInit {
  public affiliateData: AffiliateModel;
  public idBorrower: string
  public userType: string;
  public businessId: string;
  public businessLogo: string
  public investor: string
  public transfersEnabled: string;
  public linksEnabled: string;
  public simuladorEnabled: string;
  public reportsEnabled: string;
  public usuariosEnabled: string;
  public cobrosEnabled: string;

  constructor(private router: Router, private affiliateService: AffiliateService, private userService: UserService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.businessId = localStorage.getItem('businessId')
    this.userType = localStorage.getItem('userType')
    this.idBorrower = localStorage.getItem('idBorrower')
    this.investor = localStorage.getItem('inversores');
    this.linksEnabled = localStorage.getItem('links');
    this.simuladorEnabled = localStorage.getItem('simulador');
    this.cobrosEnabled = localStorage.getItem('cobros');
    this.reportsEnabled = localStorage.getItem('reports');
    this.usuariosEnabled = localStorage.getItem('usuarios');
    this.transfersEnabled = localStorage.getItem('transfers');
    this.spinnerService.emitStatus(true)
    this.affiliateService.businessData(this.businessId).subscribe((res)=>{
      this.businessLogo = res.logo
      this.transfersEnabled = JSON.stringify(res.transactionsEnabled)
      localStorage.setItem('askEnabled', JSON.stringify(res.ASKEnabled))
      this.spinnerService.emitStatus(false)
    })
    
  }

  navigateTo(idBorrower?: string){
    if(this.userType == 'prestatario'){
      this.router.navigate(['/prestatario'], { queryParams: { id: idBorrower }})
    }else if(this.userType == 'afiliado'){
      this.router.navigate(['afiliado'])
    }
  }

}
