import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-amortization',
  templateUrl: './header-amortization.component.html',
  styleUrls: ['./header-amortization.component.scss']
})
export class HeaderAmortizationComponent implements OnInit {
public idBorrower: string;
public cuotasRetrasadas: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  @Input() isAMA:boolean

  ngOnInit(): void {
    this.idBorrower = localStorage.getItem('idBorrower')
    this.cuotasRetrasadas = localStorage.getItem('cuotasRet')
}


  navigateTo(){
    this.router.navigate(['prestatario'], { queryParams: { id: this.idBorrower }})
  }
  
}
