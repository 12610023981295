import { DocumentsBorrowerComponent } from './modules/borrower/documents-borrower/documents-borrower.component';
import { LoginVTFComponent } from './modules/affiliate/loginVTF/login-vtf/login-vtf.component';
import { HowItWorksComponent } from './modules/finance/how-it-works/how-it-works/how-it-works.component';
import { FaqAboutComponent } from './modules/about/faq-about/faq-about/faq-about.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CookiesInfoComponent } from './modules/legal-info/cookies-info/cookies-info.component';
import { GeneralConditionsComponent } from './modules/legal-info/general-conditions/general-conditions.component';
import { PaymentIdConditionsComponent } from './modules/legal-info/payment-id-conditions/payment-id-conditions.component';
import { PrivacyPolicyComponent } from './modules/legal-info/privacy-policy/privacy-policy.component';
import { RessetingPassComponent } from './modules/resseting-pass/resseting-pass.component';
import { ConfiaFiveComponent } from './modules/about/confia-five/confia-five.component';
import { ConfiaTenComponent } from './modules/about/confia-ten/confia-ten.component';
import { ConfiaTwentyComponent } from './modules/about/confia-twenty/confia-twenty.component';

import { AuthGuard } from './core/services/guards/auth.guard';
import { HowAboutComponent } from './modules/about/how-about/how-about/how-about.component';
import { WhyFinancingComponent } from './modules/finance/why-financing/why-financing/why-financing.component';
import { ToFinanceComponent } from './modules/finance/to-finance/to-finance/to-finance.component';
import { SignUpComponent } from './modules/finance/sign-up/sign-up/sign-up.component';
import { FaqComponent } from './modules/finance/faq/faq/faq.component';
import { CheckEmailComponent } from './modules/resseting-pass/check-email/check-email.component';
import { ChangePasswordComponent } from './modules/affiliate/preferences/change-password/change-password.component';
import { ResetFormComponent } from './modules/resseting-pass/reset-form/reset-form.component';
import { UserTypeGuard } from './core/services/guards/userType.guard';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';
import { LoginAdminComponent } from './modules/login-admin/login-admin.component';
import { MaintenanceGuard } from './core/services/guards/maintenance.guard';
import { UserComercialGuard } from './core/services/guards/userComercial.guard';


const routes: Routes = [
   {
    path: '', loadChildren: () => import("./modules/landing/landing.module").then(m => m.LandingModule),
    data:{
      title: 'Home',
      description: 'Financiación en Confia',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'afiliado',
    loadChildren: () => import("./modules/affiliate/affiliate.module").then(m => m.AffiliateModule),
    canActivate: [AuthGuard, UserTypeGuard, MaintenanceGuard],
    data:{ 
      title: 'Afiliado',
      description: 'Incrementa tus ventas ofreciendo nuevas formas de financiación a tus clientes.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'financiar',
    loadChildren: () => import("./modules/finance/finance.module").then(m => m.FinanceModule),
    data:{
      title: 'Financiar'
    }
  },
  { 
    path: 'crea-tu-cuenta', loadChildren: () => import("./modules/register/register.module").then(m => m.RegisterModule),
    data:{
      title: 'Crea tu cuenta',
      description: 'Crea una cuenta en Confia en un solo paso.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  { 
    path: 'contactanos', loadChildren: () => import("./modules/contact/contact.module").then(m => m.ContactModule),
    data:{
      title: 'Contáctanos',
      description: 'Para que contactes con nosotros en cualquier momento si tienes una duda, problema o sugerencia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'mapa-web', loadChildren: () => import("./modules/web-map/web-map.module").then(m => m.WebMapModule),
    data:{
      title: 'Mapa web',
      description: 'No te pierdas nunca. Encuentra donde quieres ir utilizando nuestro mapa web.'
    }
  },
  {
    path: 'diccionario', loadChildren: () => import("./modules/dictionary/dictionary.module").then(m => m.DictionaryModule),
    data:{
      title: 'Diccionario',
      description: 'Todos los términos que utilizamos en Confia, explicados.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'condiciones-uso', loadChildren: () => import("./modules/legal-info/legal-info.module").then(m => m.LegalInfoModule),
    data:{
      title: 'Condiciones de uso',
      description: 'Dale un vistazo a las condiciones de uso de Confia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'ayuda', loadChildren: () => import("./modules/affiliate/help/help.module").then(m => m.HelpModule),
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title: 'Ayuda'
    }
  },
  {
    path: 'documentos', loadChildren: () => import("./modules/affiliate/documents/documents.module").then(m => m.DocumentsModule),
    canActivate: [AuthGuard,UserComercialGuard, MaintenanceGuard],
    data:{
      title: 'Documentos'
    }
  },
  {
    path: 'prestatario', loadChildren: () => import("./modules/borrower/borrower.module").then(m => m.BorrowerModule),
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title:'Prestatario'
    }
  },
  {
    path: 'subida-documentos',component: DocumentsBorrowerComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title: localStorage.getItem('financingCode') ? localStorage.getItem('financingCode') : 'Subida de documentos'
    }
  },
  { 
    path:'condiciones-generales', component: GeneralConditionsComponent,
    data:{
      title: 'Condiciones generales',
      description: 'Consulta nuestras condiciones generales para enterarte bien de como funciona Confia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    } 
  },
  { 
    path:'condiciones-entidad-pago', component: PaymentIdConditionsComponent,
    data:{
      title: 'Condiciones entidad pago',
      description: 'Utilizamos la entidad de pago certificada Lemon Way, estas son sus condiciones.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }  
  },
  { 
    path:'politica-privacidad', component: PrivacyPolicyComponent,
    data:{
      title: 'Política de privacidad',
      description: 'Dale un vistazo a la política de privacidad de Confia.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }   
  },
  {
     path:'cookies', component: CookiesInfoComponent,
     data:{
      title: 'Cookies',
      description: 'Dale un vistazo a la política de cookies de Confia.'
    }    
  },
  {
    path:'resseting/request', component: RessetingPassComponent,
    data:{
      title: 'Cambiar contraseña/email'
    } 
  },
  { 
    path: 'resetting/check-email', component: CheckEmailComponent,
    data:{
      title: 'Cambiar email'
    } 
  },
  { 
    path: 'reset-password', component: ResetFormComponent,
    data:{
      title: 'Cambiar contraseña'
    } 
  },
  {
    path: 'profile/change-password', component: ChangePasswordComponent,
    data:{
      title: 'Perfil/Cambio de contraseña'
    }
  },
 // { path: 'table', loadChildren: () => import('./shared/components/table/table.module').then(m => m.TableModule) },
  {
    path: 'que-es-confia', loadChildren: () => import("./modules/about/about.module").then(m => m.AboutModule),
    data:{
      title: '¿Qué es Confia?',
      description: 'Confia te ofrece financiación en punto de venta',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  { 
    path: 'confia5', component: ConfiaFiveComponent,
    data:{
      title: 'Confia 5',
      description: 'Confia 5',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    } 
  },
  { 
    path: 'confia10', component: ConfiaTenComponent,
    data:{
      title: 'Confia 10',
      description: 'Confia 10',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    } 
  },
  {
    path: 'confia20', component:ConfiaTwentyComponent,
    data:{
      title: 'Confia 20',
      description: 'Confia 20',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'como-funciona', component: HowAboutComponent,
    data:{
      title: 'Cómo funciona',
      description: 'Financiar a tus clientes con nuestra plataforma es muy sencillo y rápido en tres simples pasos.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'preguntas-confia', component: FaqAboutComponent,
    data:{
      title: 'Preguntas',
      description: 'Respondemos a las dudas que puedas tener sobre como financiar a tus clientes.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'afiliados-porque-financiar', component: WhyFinancingComponent,
    data:{
      title: 'Por qué financiar',
      description: 'Porqué financiar a tus clientes te aporta grandes ventajas tanto a ti como a ellos.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'afiliados', component: ToFinanceComponent,
    data:{
      title: 'Afiliados',
      description: 'Incrementa tus ventas ofreciendo nuevas formas de financiación a tus clientes.'
    }
  },
  {
    path: 'afiliados-como-funciona', component: HowItWorksComponent,
    data:{
      title: '¿Cómo funciona?',
      description: 'Financiar a tus clientes con nuestra plataforma es muy sencillo y rápido en tres simples pasos.'
    }
  },
  {
    path: 'afiliado-registrate', component: SignUpComponent,
    data:{
      title: 'Regístrate',
      description: 'Regístrate para conseguir más ventas y opciones de pago flexibles para tus clientes.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'preguntas-afiliados', component: FaqComponent,
    data:{
      title: 'Preguntas',
      description: 'Respondemos a las dudas que puedas tener sobre como financiar a tus clientes.',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }
  },
  {
    path: 'VTF/:hash/:id', component: LoginVTFComponent,
    data:{
      title: 'Confia'
    }
  },
  {
    path: 'cu/:id/:token', component: LoginAdminComponent,
    data:{
      title: 'Login'
    }
  },
  {
    path: 'preferencias',
    loadChildren: () => import("./modules/affiliate/preferences/preferences.module").then(m => m.PreferencesModule),
    canActivate: [AuthGuard, MaintenanceGuard],
    data:{
      title: 'Preferencias'
    }
  },
  { 
    path:'mantenimiento', component: MaintenanceComponent,
    data:{
      title: 'Mantenimiento',
      description: 'Mantenimiento',
      keywords: 'Financiación, Inversor, Credito personal, Peer to Peer Lending, P2P Lending, Rentabilidad, Prestamo personal, Invertir, Intereses, Crowdlending'
    }   
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
