<!-- <div class="app-bar" *ngIf="canUseApp">
    <p>¿Prefieres usar la aplicación de Confia?</p>
    <button mat-flat-button color="accent">Abir App</button>
</div> -->
<ng-container *ngIf="!isAppUser">
    <app-header-landing *ngIf="!hiddenHeader" ></app-header-landing>
    <app-header-loggin-in *ngIf="hiddenHeader" [enabledPaths]="enabledPaths"
        [name]="name"></app-header-loggin-in>
    <div [ngClass]="{
    'affiliateMode':afilliateType,
    'borrowerMode':borrowerType,
    'landingMode':!afilliateType && !borrowerType
}">
        <router-outlet><app-spinner></app-spinner></router-outlet>
    </div>
    <app-footer-landing [hiddenHeader]="hiddenHeader"></app-footer-landing>
</ng-container>

<ng-container *ngIf="isAppUser">
    <div class="appRenderMode">
        <router-outlet></router-outlet>
    </div>
</ng-container>