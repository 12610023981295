import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from '../user/userData.service';
import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class UserComercialGuard  {
  public userType: string;
  constructor( private auth: AuthService, private router: Router, private userService: UserDataService){}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.userType = localStorage.getItem('userType')
    if(this.userType != 'comercial'){
        return true
    }else{
      this.auth.logout();
      this.router.navigate(['/'])
      return false 
    }
  }
  
}


